import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
// import Details from '../Details/Details';
import Related from '../Related/Related'
import Details from '../Details/Details';

const styles = theme => ({
  root: {
  }
})

const Body = props => {

  const {
    classes,
    history,
    state,
    dispatch,
    sessionData,
    fetchList
  } = props;

  let bodyContent;

  switch (state.tabState) {
    case 0:
      bodyContent = (
        <Details
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
          fetchList={fetchList}
        />
      )
      break;
    case 8:
      bodyContent = (
        <Related
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
        />
      )
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func
}

export default withStyles(styles)(Body);
