import React, { } from 'react';
import PropTypes from 'prop-types';

function InfoBox(props) {

  return (
    <div className={'kada-info-box'}>
      <h3 className={'mb-2'}>Manage Users</h3>
      <div style={{display:'flex',alignItems:'flex-start'}}>
        <p className={'kada-info-box-description'} style={{marginBottom:0}}>
          This page is used to manage onboarded users and K detected users.
          {'\n\n'}
          K has two types of users:
          {'\n'}
          <ul style={{paddingInlineStart:16}}>
            <li>
              <span style={{fontWeight:700}}>Onboarded: </span>These are individuals that have been onboarded and logged into K at least once.
            </li>
            <li>
              <span style={{fontWeight:700}}>Reference: </span>There are users that have been detected by K in databases or tools that have been integrated. These users could be an Individual or a System account.  When K detects a new Reference User, the default setting is to classify it as an 'Individual'. It's important to review this setting and confirm if the usage is as an 'Individual' or 'System' as this impacts the K analytics that calculates Trust Score, Usage and Zone tags.
            </li>
          </ul>
          Using this page, you can update user names, associated email address, usage type and add the user to teams.
          {'\n\n'}
          To onboard a new user, modify a users' K role profile or delete an existing user, go to the <span className={'link link-info'} onClick={()=>window.open(document.location.protocol + "//" + document.location.hostname + `/keycloak/auth/admin/kada/console/#/kada/users`,'_blank')}>User Management Portal</span>.
        </p>
      </div>
    </div>
  )
}

InfoBox.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default InfoBox;
