import React, { useEffect } from 'react';
import MergedList from './MergedList';
import { getIconComponent, setHelpWdigetVisibility } from '../../../utilities';
import theme from '../../../theme';
import { KadaBtn, kButtonVariants } from 'kada-component-library';


const Merge = props => {

  const {
    state,
    dispatch,
    history,
  } = props;


  useEffect(()=>{
    setHelpWdigetVisibility(true)
    return ()=>{setHelpWdigetVisibility(false)}
  // eslint-disable-next-line
  },[])

  return (
    <div>
      <div className={'kada-info-box'}>
        <div style={{display:'flex',alignItems:'flex-start'}}>
          <div>
            <h3 className={'mb-2'}>Merge Users</h3>
            <p className={'kada-info-box-description'}>
              The same user for example, may exist in databases and reporting tools as different accounts. <span style={{fontWeight:700}}>Jane Doe</span> may be <span style={{fontWeight:700}}>jdoe123</span> in Snowflake and <span style={{fontWeight:700}}>jane.doe@company.com</span> in Tableau.
              Using the Merge feature, you can merge <span style={{fontWeight:700}}>jdoe123</span> and <span style={{fontWeight:700}}>jane.doe@company.com</span> into the <span style={{fontWeight:700}}>Jane Doe</span> profile on K.{`\n\n`}
            </p>
            <h3 className={'mb-2'}>Merge Sources</h3>
            <p className={'kada-info-box-description'}>
              Where a user has re-named the underlying source of a database, K is unable to automatically link the database source. These unlinked database sources are treated as a reference source and K will show limited information and lineage. If the underlying source has already been linked to K, you can merge the underlying source to the correct source via the <span className={'link link-info'} onClick={()=>history.push(`/admin/sources`)}>Sources</span> page and clicking on the <span style={{position:'relative',bottom:-5,display:'inline-block'}}>{getIconComponent({label:'merge',size:20,colour:theme.palette.primaryText.light})}</span> icon
            </p>
            <p className={'kada-info-box-description'}>
              Note: Merge/Unmerge is a batch process that typically runs overnight. Changes may take up to 24 hours to complete
            </p>
          </div>
          <KadaBtn text='MERGE' variant={kButtonVariants.successContained} style={{marginTop:24}} onClick={()=>dispatch({type:'set_tab_state',tabState:-1})}>
          </KadaBtn>
        </div>
      </div>
      <MergedList
        state={state}
        dispatch={dispatch}
        history={history}
      />
    </div>
  )
}

export default Merge;
