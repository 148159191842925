import { KadaAvatar, KadaBadge, KadaTableHeader, kLoadingSpinnerVariants, KadaLoadingSpinner, KadaPagination, kBadgeVariants, KadaHorizExpansion, KadaIconBtn, KadaInput, KadaFilter, KadaIcon } from 'kada-component-library';
import React, {useState} from 'react';
import { getInitials, toTitleCase } from '../../../utilities';
import CustomMenu from '../../UI/ContextMenu/ContextMenu';
import ContextMenuHolder from '../../UI/ContextMenu/ContextMenuHolder';
import { onOpenContextHolder } from '../../UI/ContextMenu/utils';
import moment from 'moment';
import KTooltip from '../../UI/KTooltip/KTooltip';

const IssueList = props => {

  const {
    state,
    history,
    dispatch,
    loadIssues,
    statusData,
    priorityData,
    sessionData
  } = props;

  const [forceExpand, setForceExpand] = useState(false);

  const setSelectedStatus = status => {
    let newFilters = [...state.filters];
    if(status==='All')newFilters = newFilters.filter(f=>!f.includes('workflow_status_srt:'))
    else newFilters = newFilters.filter(f=>!f.includes('workflow_status_srt:')).concat(`workflow_status_srt:"${status}"`)
    dispatch({
      type: 'set_filters',
      filters: newFilters
    })
    dispatch({
      type: 'set_page',
      page: 1
    })
    loadIssues({ filters: newFilters })
  }

  const setSelectedPriority = priority => {
    let newFilters = [...state.filters];
    if(priority==='All')newFilters = newFilters.filter(f=>!f.includes('priority_srt:'))
    else newFilters = newFilters.filter(f=>!f.includes('priority_srt:')).concat(`priority_srt:"${priority}"`)
    dispatch({
      type: 'set_filters',
      filters: newFilters
    })
    dispatch({
      type: 'set_page',
      page: 1
    })
    loadIssues({ filters: newFilters })
  }

  const setSelectedCreator = creator => {
    let newFilters = [...state.filters];
    if(creator==='All')newFilters = newFilters.filter(f=>!f.includes('created_by_msrt:'))
    else newFilters = newFilters.filter(f=>!f.includes('created_by_msrt:')).concat(`created_by_msrt:"${creator}"`)
    dispatch({
      type: 'set_filters',
      filters: newFilters
    })
    dispatch({
      type: 'set_page',
      page: 1
    })
    loadIssues({ filters: newFilters })
  }

  const setSelectedAssignee = assignee => {
    let newFilters = [...state.filters];
    if(assignee==='All')newFilters = newFilters.filter(f=>!f.includes('assignee_msrt:'))
    else newFilters = newFilters.filter(f=>!f.includes('assignee_msrt:')).concat(`assignee_msrt:"${assignee}"`)
    dispatch({
      type: 'set_filters',
      filters: newFilters
    })
    dispatch({
      type: 'set_page',
      page: 1
    })
    loadIssues({ filters: newFilters })
  }

  const getIssueName = ( fullName, isTrim ) => {
    let name = fullName.split(':').slice(1).join(':')
    if(isTrim){
      if(name.length>80){
        return name.slice(0,80)+'...'
      }
    }
    return name
  }

  const getIssueId = fullName => {
    return fullName.split(':')[0]
  }

  // trim the start of url
  const trimUrlHead = (str, len) => {
    return str.length > len ? '...' + str.slice(str.length - len) : str;
  }

  return (
    <div>
      <div className="flex items-center gap-4 flex-wrap">
        <KadaFilter
          data-test-id="status-filter"
          placeholder='All status'
          value={state.filters.find(f=>f.includes('workflow_status_srt:'))?.split(':')[1]?.split("\"")[1] || 'All'}
          isHidePlaceholderMenuItem
          isHideSearch
          valueDisplayPrefix='Status: '
          options={
            [
              {text:'All',value:'All'},
              ...statusData?.map(e=>{
                return {text:e.name,value:e.name}
              })
            ]
          }
          onChange={e=>{
            setSelectedStatus(e.detail.value)
          }}
        />
        <KadaFilter
          data-test-id="priority-filter"
          placeholder='All priority'
          value={state.filters.find(f=>f.includes('priority_srt:'))?.split(':')[1]?.split("\"")[1] || 'All'}
          isHidePlaceholderMenuItem
          isHideSearch
          valueDisplayPrefix='Priority: '
          options={
            [
              {text:'All',value:'All'},
              ...priorityData?.map(e=>{
                return {text:e.name,value:e.name}
              })
            ]
          }
          onChange={e=>{
            setSelectedPriority(e.detail.value)
          }}
        />
        <KadaFilter
          data-test-id="creator-filter"
          key={state.filters.find(f=>f.includes('created_by_msrt:'))?.split(':')[1]?.split("\"")[1]}
          placeholder='Creator'
          value={state.filters.find(f=>f.includes('created_by_msrt:'))?.split(':')[1]?.split("\"")[1] || 'All'}
          isHidePlaceholderMenuItem
          isHideSearch
          valueDisplayPrefix='Created by: '
          options={
            [
              {text:'All',value:'All'},
              {text:'Me',value:sessionData.name},
            ]
          }
          onChange={e=>{
            setSelectedCreator(e.detail.value)
          }}
        />
        <KadaFilter
          data-test-id="assignee-filter"
          key={state.filters.find(f=>f.includes('assignee_msrt:'))?.split(':')[1]?.split("\"")[1]}
          placeholder='Assignee'
          value={state.filters.find(f=>f.includes('assignee_msrt:'))?.split(':')[1]?.split("\"")[1] || 'All'}
          isHidePlaceholderMenuItem
          isHideSearch
          valueDisplayPrefix='Assigned to: '
          options={
            [
              {text:'All',value:'All'},
              {text:'Me',value:sessionData.name},
            ]
          }
          onChange={e=>{
            setSelectedAssignee(e.detail.value)
          }}
        />
        <div className='flex-grow'></div>
        <KadaHorizExpansion
          direction="left"
          bodyWidth="200px"
          forceExpand={forceExpand}
        >
          <div slot="root">
            <KadaIconBtn iconName="search" size={24} />
          </div>
          <div slot="body">
            <KadaInput
              data-test-id="issue-search"
              placeholder='Search issue board'
              isSearch
              autoInputDebounce
              onInput={e => {
                dispatch({
                  type: 'set_search',
                  search: e.detail.value
                })
                dispatch({
                  type: 'set_page',
                  page: 1
                })
                loadIssues({ search: e.detail.value, page: 1 })
              }}
              onMouseEnter={()=>{
                setForceExpand(true)
              }}
              onMouseLeave={()=>{
                if(state.search)return;
                setForceExpand(false)
              }}
            />
          </div>
        </KadaHorizExpansion>
      </div>
      {
        state.issueData?.docs?.length > 0 &&
        <div>
          <table className="table table-wrap" id="issue-table">
            <KadaTableHeader
              tableId='issue-table'
              options={[
                {name:'ID',sortable:false},
                {name:'Name',value:"name_srt",sortable:true},
                {name:'Priority',value:"priority_nsrt",sortable:true},
                {name:'Created',value:"created_at_srt",sortable:true},
                {name:'External url',sortable:false},
                {name:'Status',value:"workflow_status_nsrt",sortable:true},
                {name:'Assignee',sortable:false},
              ]}
              onChange={e=>{
                dispatch({type:'set_sort',sort:e.detail.value})
                loadIssues({sort:e.detail.value})
                dispatch({type:'set_page',page:1})
              }}
              value={state.sort}
            />
            <tbody>
              {
                state.issueData.docs.map((el, index) => {
                  return (
                    <>
                      <tr
                        key={el.id}
                        data-test-classname="issue-row"
                        className="hover:bg-(--color-base-200)"
                        onContextMenu={e=>{
                          e.preventDefault()
                          onOpenContextHolder({
                            x: e.clientX,
                            y: e.clientY,
                            id: el.id
                          })
                        }}
                      >
                        <td
                          className='w-40'
                          data-test-classname="issue-row-id"
                        >
                          {getIssueId(el.name_txt)}
                        </td>
                        <td
                          data-test-classname="issue-row-name"
                        >
                          <KTooltip title={getIssueName(el.name_txt)}>
                            <a
                              className={'underline cursor-pointer'}
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(`/profile/issue/${el.id}`)
                              }}
                              href={`/profile/issue/${el.id}`}
                            >
                              {getIssueName(el.name_txt, true)}
                            </a>
                          </KTooltip>
                        </td>
                        <td
                          className='w-[10%]'
                          data-test-classname="issue-row-priority"
                        >
                          <KadaBadge
                            text={toTitleCase(el.priority_txt || 'UNKNOWN')}
                            variant={kBadgeVariants.outlined}
                            color={el.priority_txt ? priorityData.find(p => p.name.toUpperCase() === el.priority_txt.toUpperCase())?.colour : null}
                          />
                        </td>
                        <td
                          className='w-36'
                          data-test-classname="issue-row-created"
                        >
                           {moment(el.created_at_srt).format('ll')}
                        </td>
                        <td
                          className='w-[20%]'
                          data-test-classname="issue-row-external-url"
                        >
                          {
                            el.external_url_txt &&
                            <KTooltip title={getIssueName(el.external_url_txt)}>
                              <a
                                href={el.external_url_txt}
                                target="_blank"
                                rel="noreferrer"
                                className="underline cursor-pointer"
                                onContextMenu={e=>{
                                  e.stopPropagation()
                                }}
                              >
                                {trimUrlHead(el.external_url_txt, 20)}
                              </a>
                            </KTooltip>
                          }
                        </td>
                        <td
                          className='w-[10%]'
                          data-test-classname="issue-row-status"
                        >
                          <KadaBadge
                            text={toTitleCase(el.workflow_status_txt || 'UNKNOWN')}
                            variant={kBadgeVariants.outlined}
                            color={el.workflow_status_txt ? statusData.find(s => s.name.toUpperCase() === el.workflow_status_txt.toUpperCase())?.colour : null}
                          />
                        </td>
                        <td
                          className='w-[10%]'
                          data-test-classname="issue-row-assignee"
                        >
                          {
                            el.assignee_txts?.[0] ?
                            <KadaAvatar
                              text={getInitials(el.assignee_txts[0])}
                              tooltip={el.assignee_txts[0]}
                            />
                            :
                            <div
                              className="tooltip"
                              data-tip="None"
                            >
                              <KadaIcon
                                iconName="unknown_circle"
                                size={24}
                                color="var(--color-light-text)"
                              />
                            </div>
                          }
                        </td>
                      </tr>
                      <ContextMenuHolder
                        popperId={el.id}
                      >
                        <CustomMenu
                          item={el}
                          actions={[
                            'open_new_tab',
                            'bookmark',
                          ]}
                        />
                      </ContextMenuHolder>
                    </>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      }
      {
        state.issueDataLoading &&
        <div className="flex justify-center">
          <KadaLoadingSpinner variant={kLoadingSpinnerVariants.secondary} />
        </div>
      }
      {
        state.issueData?.numFound===0 &&
        <p>
          No issues found
        </p>
      }
      {
        state.issueDataError &&
        <p>Error occurred loading issues</p>
      }
      {
        !state.issueDataLoading && state.issueData?.docs.length>0 &&
        <KadaPagination
          maxPage={Math.ceil(state.issueData.numFound/state.perPage)}
          currentPage={state.page}
          onChange={event => {
            dispatch({type:'set_page',page:event.detail.value})
            loadIssues({page:event.detail.value})
            window.scrollTo({top:0,behavior:'smooth'})
          }}
        />
      }
    </div>
  )
}


export default IssueList;
