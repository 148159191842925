import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, InputBase, Typography, Button } from '@material-ui/core';
import Editor from '../Editor/Editor';
import { toTitleCase, getLabelPlural, mapObjectName, sendMessage, titleCaseObjectName, getDispFields } from '../../../utilities';
import { useStore } from 'react-redux'
import axiosSolr from '../../../axios-solr'
import axiosCerebrum from '../../../axios-cerebrum'
import NotificationIcon from '@material-ui/icons/NotificationImportant'
import { useDispatch } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import { withRouter } from 'react-router'
import '@toast-ui/editor/dist/toastui-editor-viewer';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';
import { Editor as MDEditor } from '@toast-ui/react-editor';
import { cleanUpMountedEls, getAllRules, postSaveAction, templates } from '../InteractiveInput/Templates';
import InteractiveInputBody from '../InteractiveInput/InteractiveInputBody';
import AddCIModal from '../InteractiveInput/AddCIModal';
import { getPutPayload, processDescriptionWithTerm, sanitiseInput } from './utils';
import { globalListenerRef } from '../../../GlobalListenerRef';
import KTooltip from '../KTooltip/KTooltip';
import { checkProfileEditable } from '../../../permissionChecker';
import InteractiveViewer from '../InteractiveInput/InteractiveViewer';
import { generateDescription } from './genAIUtils';
import { GenAILoadingIcon, checkIsObjectValidForGenAI, explainationText, hasLLMKeySet, overwriteText, triggerText } from './genAIComponents';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  textField: {
    ...theme.components.inputBase,
    flexGrow: 1,
    marginRight: '1rem',
    width: '100%',
    '& div': {
      padding: 10
    },
  },
  bodyText: {
    flexGrow: 1,
    color: theme.palette.primaryText.main,
    width: '65%',
    minWidth: 540,
    paddingTop: 4,
    paddingBottom: 4,
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  genAIButton: {
    ...theme.components.genAIButton
  },
  overwriteGenAIButton: {
    ...theme.components.overwriteGenAIButton
  },
  button: {
    padding: '2px 8px'
  },
  suggestionChip: {
    display: 'flex',
    padding: '10px 16px',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.listItemDivider.main}`
  }
})

// this component is designer for update description only

function UpdateInput(props) {

  const {
    classes,
    theme,
    history,
    label,
    property,
    placeholder,
    multiline,
    textLabel,
    initialValue,
    defaultText,
    fetchList,
    data,
    title,
    subtitle,
    hideSubtitle,
    url,
    disableEditing,
    wordLimit = 3000,
    textfieldRows = 4,
    enableMarkDown,
    enableWidget,
    state,
    defaultEditing,
    customAddOnComponent,
    buttonAlignment,
    onCancelEdit,
    onSaveEdit,
    onError
  } = props;


  const store = useStore();
  const sessionData = store.getState().auth.session_user;
  // const roles = getUserRoles(sessionData.user_role)
  const reduxDispatch = useDispatch()
  const MDRef = useRef()
  const [refreshFlag, setRefreshFlag] = useState(1)

  let isDisableEdit = disableEditing;
  if (property === 'description' && !checkProfileEditable({ sessionData, isStewardOrOwner: state.isStewardOrOwner }) && label !== 'issue') {
    isDisableEdit = true;
  }

  const [text, setText] = useState(sanitiseInput({ property, value: initialValue || '' }));
  const [previousText, setPreviousText] = useState(sanitiseInput({ property, value: initialValue || '' }));
  const [updating, setUpdating] = useState(false);
  const [AIGenerating, setAIGenerating] = useState(false)
  const [displayText, setDisplayText] = useState(sanitiseInput({ property, value: initialValue || '' }));
  const [isAIGenerated, setIsAIGenerated] = useState(false)

  const [suggestions, setSuggestions] = useState();
  const [showMoreSuggestions, setShowMoreSuggestions] = useState(false);
  const [sourceName, setSourceName] = useState();
  const [sameNameObjectCount, setSameNameObjectCount] = useState()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(() => {
    return () => {
      isCancelledRef.current = true
    }
  }, [])


  useEffect(() => {
    setText(sanitiseInput({ property, value: initialValue || '' }))
  }, [initialValue, property])

  const loadTermSuggestion = (originalText, forceUnlinkedTerm, forceLinkedTerm) => {
    if (!document.getElementById('profile-glossary-adder')) {
      return;
    };
    processDescriptionWithTerm({
      text: originalText,
      onFinish: ({ newText }) => { newText.trim() !== '' && setDisplayText(newText) },
      targetData: data,
      forceUnlinkedTerm,
      forceLinkedTerm
      // relatedTerms: state.terms?state.terms.items.filter(el=>el.relationship==='RELATED'):undefined
    })
  }

  useEffect(() => {
    if (property === 'description' && enableWidget) {
      loadTermSuggestion(sanitiseInput({ property, value: initialValue || '' }))
    }
    // eslint-disable-next-line
  }, [initialValue])

  useEffect(() => {
    const onMsgReceived = (msg) => {
      if (msg.data.reload_related_terms && property === 'description' && enableWidget) {
        loadTermSuggestion(text)
      }
      if (msg.data[`set_text_${property}_${data.id}`]) {
        setText(sanitiseInput({ property, value: msg.data[`set_text_${property}_${data.id}`] || '' }))
        setRefreshFlag(refreshFlag + 1)
      }
    }
    window.removeEventListener('message', globalListenerRef.reloadTermSuggestionListener);
    globalListenerRef.reloadTermSuggestionListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.reloadTermSuggestionListener);
    return (() => { window.removeEventListener('message', globalListenerRef.reloadTermSuggestionListener); })
    // eslint-disable-next-line
  }, [text, refreshFlag, property, enableWidget])

  const loadExtraInfo = () => {
    if (!['table', 'column', 'dataset_table', 'dataset_field'].includes(label) || property !== 'description') return;
    if (suggestions) return;
    axiosSolr
      .get(
        `/solr/search/select`, {
        params: {
          q: `name_srt:"${data.name}"`,
          fq: `object_type_srt:${mapObjectName(label)} AND source_id_srt:${data.source_id}`,
          rows: 0,
          'json.facet': {
            'no_description': {
              type: "query",
              q: '-description:*',
            }
          }
        }
      }
      )
      .then(response => {
        setSameNameObjectCount({ total: response.data.response.numFound, no_description: response.data.facets.no_description.count })
      })
      .catch(error => {
        console.log(error)
      })

    axiosSolr
      .get(
        `/solr/search/select`, {
        params: {
          q: `name_srt:"${data.name}"`,
          fq: `object_type_srt:${mapObjectName(label)} AND -id:${data.id} AND description:* AND source_id_srt:${data.source_id}`,
          fl: 'description',
          rows: 20,
          start: 0,
          sort: 'trust_srt desc'
        }
      }
      )
      .then(response => {
        let validSuggestions = response.data.response?.docs?.filter(el => el.description && el.description.trim() !== '');
        let finalSuggestions = [];
        validSuggestions?.forEach(el => {
          if (finalSuggestions.find(f => f.description === el.description)) {
            finalSuggestions.find(f => f.description === el.description).count += 1;
          } else {
            finalSuggestions.push({ ...el, count: 1 })
          }
        })
        finalSuggestions.sort((a, b) => b.count - a.count).slice(0, 5)
        finalSuggestions = finalSuggestions.filter(el => el.description !== initialValue)
        axiosCerebrum
          .get(`/api/sources/${data.source_id}`)
          .then(response => {
            setSourceName(response.data.name)
            setSuggestions(finalSuggestions)
          })
      })
  }

  useEffect(() => {
    // if(initialValue && initialValue!=='')return;
    loadExtraInfo()
    return () => {
      cleanUpMountedEls()
    }
    // eslint-disable-next-line
  }, [])

  const updateValue = async value => {
    setIsAIGenerated(false)
    setUpdating(true);
    let method = 'put';
    if (data.object?.name === 'ISSUE') method = 'patch';
    await axiosCerebrum
    [method](
      url,
      getPutPayload({ property, value, data })
    )
      .then((response) => {
        setPreviousText(value);
        setShowMoreSuggestions(false)
        setUpdating(false);
        if (value.trim() === '') {
          loadExtraInfo()
        }
        onSaveEdit?.(response.data)
        fetchList(response.data);
        if (enableWidget) {
          postSaveAction({
            item: data,
            initialValue: initialValue,
            newValue: value,
            // onUpdate: () => {
            //   // window.postMessage({ reload_related_terms: true, text: value }, document.location.protocol + "//" + document.location.hostname + ':' + document.location.port)
            // }
          })
        }
        // loadTermSuggestion(value);
      })
      .catch(error => {
        console.log(error)
        let msg = `Error occurred updating the ${property}, please try again`
        if (onError) {
          onError(msg)
        } else {
          sendAlert({ message: msg, type: 'error' })
        }
        setText(sanitiseInput({ property, value: initialValue || '' }));
        setShowMoreSuggestions(false)
        setUpdating(false);
      })
  }


  useEffect(() => {
    if (!text || text.length === 0) {
      if (defaultText) {
        setDisplayText(defaultText);
        return;
      }
      setDisplayText(isDisableEdit ? `No ${property.toLowerCase()} provided.` : `Add a ${property.toLowerCase()}`);
    }
    else {
      setDisplayText(text);
    }
    // eslint-disable-next-line
  }, [text])

  const onSaveClick = (prevTxt = previousText, currentTxt = text) => {
    if (prevTxt === currentTxt) {
      onSaveEdit?.(data)
      // setEditing(prevVal => !prevVal);
      return;
    }
    updateValue(sanitiseInput({ property, value: currentTxt.replace(/^(<br>|\s)*/, '').replace(/(<br>|\s)*$/, '') }))
  }

  const onApplySuggestion = (value) => {
    setText(sanitiseInput({ property, value: value || '' }));
    sendMessage({ [`close-editor-${data.id}`]: true })
    setTimeout(() => onSaveClick(previousText, value))
  }

  const onBulkEditSameName = (mode) => {
    reduxDispatch({
      type: actions.REMOVE_PAGE_CACHE,
      data: window.location.href
    })
    let fq = `object_type_srt:${mapObjectName(label)} AND source_id_srt:${data.source_id}`;
    if (mode === 'no_description') {
      fq += ' AND -description:*'
    }
    let count;
    if (mode === 'all') {
      count = sameNameObjectCount.total
    } else {
      count = sameNameObjectCount.no_description
    }
    reduxDispatch({
      type: actions.SET_BULK_EDIT_PARAM,
      data: {
        url: '/solr/search/select',
        type: 'solr',
        params: {
          q: `name_srt:"${data.name}"`,
          fq: fq
        },
        redirectUrl: window.location.pathname.split('?')[0],
        editMode: 'same_name_description_only',
        presetDescription: text,
        fileName: `${count} ${titleCaseObjectName(label).toLowerCase()}s named ${data.name}`.trim(),
      }
    })
    history.push('/bulk_update')
  }

  const onCloseClick = () => {
    setIsAIGenerated(false)
    onCancelEdit?.()
    if (previousText === text) {
      //setEditing(prevVal => !prevVal);
      return;
    }
    setText(previousText);
  }

  let defaultSubtitle = `Describe the ${label} purpose, uses, time range and other attributes`;
  const maxWidth = 720;

  const AIButton = (
    !isDisableEdit && !AIGenerating && checkIsObjectValidForGenAI(label) && !updating && !isAIGenerated &&
    hasLLMKeySet() &&
    <div
      className={text.trim() === '' ? classes.genAIButton : classes.overwriteGenAIButton}
      onClick={() => {
        sendMessage({ [`open-editor-${data.id}`]: true })
        generateDescription({
          data,
          onLoading: () => {
            setAIGenerating(true)
          },
          onError: (msg) => {
            setAIGenerating(false)
            sendAlert({
              message: msg || 'Error generating description, please try again',
              type: 'error',
              timeout: 99999999
            })
          },
          onSuccess: (value) => {
            setAIGenerating(false)
            setText(sanitiseInput({ property, value: value || '' }))
            setRefreshFlag(refreshFlag + 1)
            postMessage({ [`open-editor-${data.id}`]: true })
            setIsAIGenerated(true)
          }
        })
      }}
    >
      {text.trim() === '' ? triggerText : overwriteText}
    </div>
  )

  return (
    <Editor
      id={data.id}
      title={title || property.toUpperCase()}
      subtitle={hideSubtitle ? '' : (subtitle || defaultSubtitle)}
      defaultEditing={defaultEditing}
      body={
        <div>
          <div style={{ maxWidth: '100%', width: maxWidth }}>
            {
              enableMarkDown && text.trim() !== '' ?
                <InteractiveViewer
                  key={displayText}
                  initialValue={displayText}
                  id={property}
                  alwaysShown={true}
                  object={data}
                  enableCustomContextMenu={true}
                />
                :
                <Typography data-test-id={`md-input-viewer-${property}`} className={classes.bodyText + ' input-body-text'} style={{ color: text.trim() === '' && !isDisableEdit ? theme.palette.primaryText.light : undefined }} variant='body2'>
                  {displayText}
                </Typography>
            }
            {
              suggestions && suggestions.length > 0 && !initialValue &&
              <Typography style={{ fontSize: 12, color: theme.palette.primaryText.light, marginTop: 16, display: 'flex', alignItems: 'center', wordBreak: 'break-word' }}>
                <NotificationIcon style={{ width: 16, height: 16, marginRight: 8 }} />
                {suggestions.length} suggestion(s) from {getLabelPlural(label)} in {sourceName} with the same name. Click to review
              </Typography>
            }
          </div>
        </div>
      }
      isModal
      onSave={onSaveClick}
      onClose={onCloseClick}
      disableSaveButton={text.length > wordLimit}
      buttonAlignment={buttonAlignment}
      footer={AIButton}
      hideActionButton={AIGenerating}
      editWindow={
        <>
          <h2 className={'mb-4 text-(--color-primary)'}>
            Edit {property} for {getDispFields(data, 'dispTitle')} {toTitleCase(mapObjectName(label).replace(/_/g, ' '))}
          </h2>
          {
            AIGenerating ?
              <div style={{ marginBottom: 60, marginTop: 40 }}>
                <GenAILoadingIcon />
              </div> :
              <div>
                <Typography style={{ fontSize: 12, letterSpacing: 1, color: theme.palette.primaryText.light, marginBottom: 8 }}>
                  {property.toUpperCase()}
                </Typography>
                <div data-test-id={`${property}-edit-input`}>
                  {
                    enableMarkDown ?
                      enableWidget ?
                        <InteractiveInputBody
                          triggerChar={templates.linkCI.triggerChar}
                          initialValue={text}
                          object={data}
                          key={refreshFlag}
                          enableCustomContextMenu={true}
                          onChange={(value) => setText(sanitiseInput({ property, value: value || '' }))}
                          height={Math.min(700, Math.max(400, (text || '').length / 2.5)) + 'px'}
                          generatePopper={({ editorRef, setPopperAnchor }) => {
                            return (
                              <AddCIModal
                                editorRef={editorRef}
                                setPopperAnchor={setPopperAnchor}
                                targetItem={data}
                              />
                            )
                          }}
                        />
                        :
                        <MDEditor
                          autofocus={false}
                          ref={MDRef}
                          height={Math.min(700, Math.max(400, (text || '').length / 2.5)) + 'px'}
                          hideModeSwitch
                          onChange={() => setText(sanitiseInput({ property, value: MDRef.current.getInstance().getMarkdown() || '' }))}
                          initialValue={text}
                          key={refreshFlag}
                          initialEditType="wysiwyg"
                          useCommandShortcut={true}
                          previewStyle="vertical"
                          widgetRules={getAllRules()}
                          theme={localStorage.hasOwnProperty('dark') ? "dark" : "white"}
                          usageStatistics={false}
                          toolbarItems={
                            [
                              ['heading', 'bold', 'italic', 'strike'],
                              ['hr', 'quote'],
                              ['ul', 'ol', 'indent', 'outdent'],
                              ['table', 'link'],
                              ['code', 'codeblock']
                            ]
                          }
                        />
                      :
                      <InputBase
                        className={classes.textField}
                        value={text}
                        placeholder={placeholder}
                        disabled={updating}
                        label={textLabel}
                        multiline={multiline}
                        rows={textfieldRows}
                        rowsMax={textfieldRows}
                        onChange={event => setText(event.target.value)}
                        margin="none"
                      />
                  }
                </div>
                <div style={{ marginLeft: 16 }}>
                  {AIButton}
                </div>
                {
                  text.length > wordLimit &&
                  <Typography style={{ fontSize: 13.75, marginTop: 5, marginLeft: 16, color: theme.palette.error.main, whiteSpace: 'pre-wrap' }}>
                    Exceeds {wordLimit} character limit
                  </Typography>
                }
                {
                  isAIGenerated &&
                  <Typography style={{ fontSize: 12, marginTop: 5, marginLeft: 16, color: theme.palette.primaryText.light }}>
                    {explainationText}
                  </Typography>
                }
                {
                  customAddOnComponent
                }
                {
                  sameNameObjectCount &&
                  <div style={{ marginTop: 16 }}>
                    <Typography style={{ color: theme.palette.primaryText.main, fontSize: 12, letterSpacing: 2, marginBottom: 8, wordBreak: 'break-word' }}>
                      BULK EDIT OPTIONS
                    </Typography>
                    {
                      sameNameObjectCount.total > 1 ?
                        <>
                          {
                            sameNameObjectCount.no_description > 1 &&
                            <div className={classes.suggestionChip} >
                              <div style={{ flexGrow: 1, marginRight: 48 }}>
                                <Typography style={{ width: '100%', fontSize: 13.75, color: theme.palette.primary.main }}>
                                  Update {toTitleCase(label.replace(/_/g, ' '))}(s) called "{data.name}" in {sourceName || 'this Source'} with blank description
                                </Typography>
                              </div>
                              <Button variant='outlined' className={classes.button} color='primary' style={{ flexShrink: 0 }} onClick={() => onBulkEditSameName('no_description')}>EDIT ({sameNameObjectCount.no_description})</Button>
                            </div>
                          }
                          <div className={classes.suggestionChip}>
                            <div style={{ flexGrow: 1, marginRight: 48 }}>
                              <Typography style={{ width: '100%', fontSize: 13.75, color: theme.palette.primary.main }}>
                                Update and overwrite descriptions for all {toTitleCase(label.replace(/_/g, ' '))}(s) called "{data.name}" in {sourceName || 'this Source'}
                              </Typography>
                            </div>
                            <Button variant='outlined' className={classes.button} color='primary' style={{ flexShrink: 0 }} onClick={() => onBulkEditSameName('all')}>EDIT ({sameNameObjectCount.total})</Button>
                          </div>
                        </>
                        :
                        <div className={classes.suggestionChip} style={{ borderBottom: 'none' }}>
                          <div style={{ flexGrow: 1 }}>
                            <Typography style={{ width: '100%', fontSize: 13.75, color: theme.palette.primary.main }}>
                              {data.name} is a unique {toTitleCase(label.replace(/_/g, ' '))} in {sourceName || 'this Source'}. No bulk update options available
                            </Typography>
                          </div>
                        </div>
                    }

                  </div>
                }
                {
                  suggestions && suggestions.length > 0 &&
                  <div style={{ marginTop: 16 }}>
                    <Typography style={{ color: theme.palette.primaryText.main, fontSize: 12, letterSpacing: 2, marginBottom: 8, wordBreak: 'break-word' }}>
                      DESCRIPTION(S) FROM MATCHING {label.replace(/_/g, ' ').toUpperCase()}(S)
                    </Typography>
                    {
                      (showMoreSuggestions ? suggestions : suggestions.slice(0, 3)).map(el => (
                        <div style={{ display: 'flex', padding: '10px 16px', alignItems: 'center', borderBottom: `1px solid ${theme.palette.listItemDivider.main}` }}>
                          <div style={{ flexGrow: 1, marginRight: 48, overflow: 'hidden' }}>
                            <KTooltip title={el.description}>
                              <Typography style={{ width: '100%', overlfow: 'hidden', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 13.75, color: theme.palette.primaryText.main }}>
                                {el.description}
                              </Typography>
                            </KTooltip>
                            <Typography style={{ marginTop: 4, fontSize: 12, color: theme.palette.primaryText.light }}>
                              Used in <span style={{ fontWeight: 700 }}>{el.count}{el.count >= 20 ? '+' : ''} {toTitleCase(label.replace(/_/g, ' '))}(s)</span> named "{data.name}"
                            </Typography>
                          </div>
                          <Button variant='outlined' className={classes.button} color='primary' style={{ flexShrink: 0 }} onClick={() => onApplySuggestion(el.description)}>APPLY</Button>
                        </div>
                      ))
                    }
                  </div>
                }
                {
                  suggestions && suggestions.length > 3 &&
                  <Button color='primary' style={{ marginTop: 8 }} onClick={() => setShowMoreSuggestions(!showMoreSuggestions)}>
                    {showMoreSuggestions ? 'SEE LESS' : 'SEE MORE'}
                  </Button>
                }
              </div>
          }
        </>
      }
      disableEditing={isDisableEdit || AIGenerating}
      titleStyle={{ color: theme.palette.header.main, fontWeight: '400', fontSize: '1.25rem' }}
    />
  )
}


UpdateInput.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  multiline: PropTypes.bool,
  textLabel: PropTypes.string,
  userName: PropTypes.string,
  data: PropTypes.object,
  placeholder: PropTypes.string,
  fetchList: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  url: PropTypes.string,
  disableEditing: PropTypes.bool,
  customAddOnComponent: PropTypes.element,
  wordLimit: PropTypes.number,
  enableMarkDown: PropTypes.bool,
  enableWidget: PropTypes.bool,
  defaultEditing: PropTypes.bool,
  buttonAlignment: PropTypes.string,
  onCancelEdit: PropTypes.func,
  onSaveEdit: PropTypes.func,
  onError: PropTypes.func,
}

export default withTheme()(withStyles(styles)(withRouter(UpdateInput)));
