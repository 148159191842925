import { KadaBtn, kButtonVariants } from 'kada-component-library';
import React, {  } from 'react';

const ImportInfoBox = props => {

  const {
    history,
    dispatch
    // generateScheduleText
  } = props;

  return (
    <div className={'kada-info-box'}>
      <h3 className={"mb-2"}>Importing Manual Source metadata</h3>
      <div style={{display:'flex',alignItems:'flex-start'}}>
        <p className={'kada-info-box-description'}>
          The import source function lets you upload source metadata via an excel file. As metadata is uploaded manually, there are a number of K features that are unavailable for imported data assets:
          <ul style={{paddingInlineStart:16}}>
            <li>
              Usage analysis: Usage data (e.g. logs) cannot be imported via excel. This means usage data will not be shown in the respective dashboards, profile and extract
            </li>
            <li>
              Automated lineage: Data assets manually uploaded will need to be manually linked to lineage maps
            </li>
          </ul>
          <span style={{fontWeight:700}}>Note:</span> Upload of source metadata will fully reflect in your Data Ecosystem after the DAILY job has been. You can trigger the DAILY job anytime from the <span className={'link link-info'} onClick={()=>history.push('/admin/platform_management?tabName=BATCH MANAGER')}>Batch Manager</span> page.
        </p>
        <div>
          <KadaBtn text='IMPORT SOURCE FILE' variant={kButtonVariants.successContained} style={{marginBottom:16}} onClick={()=>{dispatch({type:"set_import_source_modal_open",importSourceModalOpen:true})}}>
          </KadaBtn>
        </div>
      </div>
    </div>
  )
}

export default ImportInfoBox;
