import React from 'react';
import PropTypes from 'prop-types';
import { KadaBtn, kButtonVariants } from 'kada-component-library';


function InfoBox(props) {

  const {
    dispatch,
    // state,
    history,
  } = props;



  return (
    <div className={'kada-info-box'}>
      <h3 className={"mb-2"}>About Importing Bulk Edit Files</h3>
      <div style={{display:'flex',alignItems:'flex-start'}}>
        <p className={'kada-info-box-description'}>
          K has the capability to process bulk metadata updates via importing an excel file. You must use a K generated bulk edit template generated from the <span className={"link link-info"} onClick={()=>history.push('/cart')}>Cart</span>
          {'\n\n'}
          Key things to check prior to importing:
          {'\n'}
          <div style={{display:'flex'}}>
            <div style={{flexShrink:0,marginRight:4}}>•</div><div>Blank cells will overwrite and delete existing metadata. Make sure cells have been left blank on purpose</div>
          </div>
          <div style={{display:'flex'}}>
            <div style={{flexShrink:0,marginRight:4}}>•</div><div>For properties where you are selecting a Collection Instance (e.g. Domain, Steward), make sure there are no typos. Any value that doesn't match an existing collection instance in K will result in a failed upload</div>
          </div>
          <div style={{display:'flex'}}>
            <div style={{flexShrink:0,marginRight:4}}>•</div><div>Bulk upload templates are only stored in K for 7 days. Check that you have saved an original clean version of the upload template. This version can act as a back-up to reverse any incorrect bulk upload errors. </div>
          </div>
        </p>
        <div style={{textAlign:'-webkit-right',flexShrink:0}}>
          <KadaBtn variant={kButtonVariants.successContained} text="IMPORT BULK EDIT FILE" style={{marginBottom:24}} onClick={()=>dispatch({type:'set_import_modal_open',importModalOpen:true})}>
          </KadaBtn>
        </div>
      </div>
    </div>
  )
}

InfoBox.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default InfoBox;
