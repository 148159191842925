import React from 'react';
import { KadaBtn, kButtonVariants } from 'kada-component-library';

const InfoBox = props => {

  const {
    state,
    history,
  } = props;

  if(state.category==='GLOSSARY'){
    return (
      <div>
        <div className={'kada-info-box'}>
          <h3 className={'mb-2'}>About Glossary Collections</h3>
          <div style={{display:'flex',alignItems:'flex-start'}}>
            <p className={'kada-info-box-description'}>
              Create a Glossary to govern your business concepts such as Terms with defined definitions.
              {'\n\n'}
              Terms are typically linkable to any data items. They are accessible to all users of K to help keep all stakeholders on the same page.
            </p>
            <KadaBtn text="CREATE GLOSSARY" data-test-id="create-collection-button" variant={kButtonVariants.successContained} onClick={()=>history.push('/create_collection?category=GLOSSARY')}>
            </KadaBtn>
          </div>
        </div>
      </div>
    )
  }

  if(state.category==='DATA_GOVERNANCE'){
    return (
      <div>
        <div className={'kada-info-box'}>
          <h3 className={'mb-2'}>About Data Governance Collections</h3>
          <div style={{display:'flex',alignItems:'flex-start'}}>
            <p className={'kada-info-box-description'}>
              Create a Data Governance collection to organise governance related concepts such as Critical Data Element.
              {`\n\n`}
              Data Governance instances can be linked to any data items. You can create rules to automatically link these instances to items using the <span onClick={()=>history.push('/admin/instance_linking')} className={"link link-info"}>Linking Rules</span> feature.
            </p>
            <KadaBtn text="CREATE COLLECTION" data-test-id="create-collection-button" variant={kButtonVariants.successContained} onClick={()=>history.push('/create_collection?category=DATA_GOVERNANCE')}>
            </KadaBtn>
          </div>
        </div>
      </div>
    )
  }

  if(state.category==='DATA_MANAGEMENT'){
    return (
      <div>
         <div className={'kada-info-box'}>
          <h3 className={'mb-2'}>About Data Management Collections</h3>
          <div style={{display:'flex',alignItems:'flex-start'}}>
            <p className={'kada-info-box-description'}>
              Create a Data Management collection to organise management related concepts such as Source System labels.
              {`\n\n`}
              Data Management instances can be linked to any data items. You can create rules to automatically link these instances to items using the <span onClick={()=>history.push('/admin/instance_linking')} className={"link link-info"}>Linking Rules</span> feature.
            </p>
            <KadaBtn text="CREATE COLLECTION" data-test-id="create-collection-button" variant={kButtonVariants.successContained} onClick={()=>history.push('/create_collection?category=DATA_MANAGEMENT')}>
            </KadaBtn>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className={'kada-info-box'}>
        <h3 className={'mb-2'}>About K Collections</h3>
        <div style={{display:'flex',alignItems:'flex-start'}}>
          <p className={'kada-info-box-description'}>
          These collections are used by K to manage, classify and organise your data and capabilities.
          {`\n\n`}
          K collections includes the following Collection types: Automated, Collaboration, Change, Knowledge, Lists & Platform.
          {`\n\n`}
          Some of these collections cannot be edited. For example instances in the Trend collection (Automated Type) cannot be changed. You can however add new changes in the Planned Change (Change Type) collection.
          </p>
        </div>
      </div>
    </div>
  )
}

export default InfoBox;
