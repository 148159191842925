import React, { useContext } from 'react';
import SearchBar from '../../SearchBar/SearchBar'
import RecommendList from '../RecommendList/RecommendList'
import { getCustomerName } from '../../../../utilities'
import { BasicSearchContext } from '../../../../containers/BasicSearch/BasicSearch';



const LandingBody = props => {

  const {
    state,
    dispatch,
    history,
    onSearch,
    indexName
  } = useContext(BasicSearchContext);

  let searchPlaceHolder;
  let customerName = getCustomerName()
  searchPlaceHolder = `Search ${customerName===''?'your':('the ' + customerName)} data ecosystem`;

  return (
    <div className={'max-w-[1180px] pr-[32px] pl-[32px] mx-auto mt-[64px] mb-[64px]'}>
      <div id={"search_tutorial_section"}>
        <SearchBar indexName={indexName} state={state} dispatch={dispatch} onSearch={onSearch} placeholder={searchPlaceHolder} isPlugin/>
      </div>
      <RecommendList state={state} dispatch={dispatch} history={history} onSearch={onSearch}/>
    </div>
  )
}

export default LandingBody;
