import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Avatar, CircularProgress } from '@material-ui/core';
import { getIconComponent, getInitials } from '../../../utilities';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import axiosCerebrum from '../../../axios-cerebrum';
import keycloakInstance from '../../../keycloak-instance';
import { connect, useStore } from 'react-redux';
import * as actions from '../../../store/actions/index';
import KTooltip from '../../UI/KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';
import useGetUserTeams from '../../../hooks/useGetUserTeams';
import { KadaLoadingSpinner, kLoadingSpinnerVariants } from 'kada-component-library';

const styles = theme => ({
  block: {
    marginBottom: '5rem',
  },
  title: {
    height: '24px',
    width: '284px',
    color: theme.palette.primary.main,
    fontSize: '13.75px',
    fontWeight: '500',
    letterSpacing: '0.1px',
    lineHeight: '24px'
  },
  spacingDivs: {
    marginBottom: '2rem'
  },
  description: {
    textAlign: 'justify',
    whiteSpace: 'pre-wrap',
    color: theme.palette.primaryText.light,
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px'
  },
  alertText: {
    textAlign: 'justify',
    //overflow:'scroll',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem',
    color: theme.palette.primaryText.light,
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px'
  },
  headerText: {
    color: theme.palette.primaryText.main,
    marginBottom: '1rem',
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px'
  },
  alertTitle: {
    '& h6': {
      color: theme.palette.primaryText.main,
    },
    fontSize: '19.64px',
    fontWeight: '500',
    letterSpacing: '0.25px',
    lineHeight: '26px'
  },
  tableTitle: {
    color: theme.palette.primary.main,
    fontSize: 12,
    letterSpacing: 2
  },
  columnRow: {
    paddingLeft: 16,
    // width:624,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    borderBottom: `1px solid ${theme.palette.listItemDivider.main}`
  },
  rowText: {
    color: theme.palette.primaryText.light,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 16
  },
  banner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 56,
    width: '100%',
    background: '#f5f5f5',
    marginBottom: 24
  },
  modalPaper: {
    background: theme.palette.background.main,
    border: theme.palette.border.main,
    width: '35vw',
    minWidth: 600,
    maxWidth: 800,
  },
  avatar: {
    fontSize: 12,
    height: 24,
    width: 24,
    backgroundColor: theme.palette.avatar.main,
    marginRight:12
  },
  itemChip:{
    width:'max-content',
    maxWidth:'100%',
    overflow:'hidden',
    display:'flex',
    alignItems:'center',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    borderRadius:16,
    padding:'4px 6px',
    marginRight:8,
    marginBottom:8,
    '& span':{
      padding:'0 8px',
      fontSize:13.75,
      // marginLeft:4
    }
  },
  targetItemChip:{
    background:theme.palette.primary.dark,
    color:theme.palette.background.main
  },
  tooltip: {
    fontSize: 13.75
  }
})

const ConfirmProfile = props => {

  const {
    theme,
    classes,
    state,
    dispatch,
    history,
    onLoginSuccess
  } = props;

  // const dispatchRedux = useDispatch()

  const [submitting, setSubmitting] = useState()

  const store = useStore();
  let sessionData = store.getState().auth.session_user;

  const {
    data: existingtUserGroupData,
    loading: existingtUserGroupLoading,
  } = useGetUserTeams({ userId: sessionData.id})


  let receiveEmailSettingID;
  try{
    receiveEmailSettingID = JSON.parse(localStorage.getItem('userSettings')).items.find(el=>el.user_settings_template.name==='Enable Daily Briefing Email').id;
  }catch{}

  const {
    sendAlert
  } = useAlert({})


  function handleClose() {
    dispatch({ type: 'set_alert', alert: false })
  }

  function createUserOnboard() {
    setSubmitting(true)
    axiosCerebrum
      .get('/api/me')
      .then(async response => {
        let data = {};
        data.user = { ...keycloakInstance.tokenParsed, ...response.data };
        data.user.id = response.data.id;
        data.keycloak = keycloakInstance;
        if (state.alias.length > 0) {
          await axiosCerebrum.post(
            `/api/merges`,
            {
              "target": response.data.id,
              "object_type": "USER",
              "candidates": state.alias.map(el => el.id),
              "action": 'ADD_TO_EXISTING'
            }
          )
        }

        if (state.team.length > 0) {
          let teams = [];

          let promises = []
          // add new team links
          for (let i = 0; i < state.team.length; i++) {
            if (existingtUserGroupData?.find(g => g.id === state.team[i].id)) {
              teams.push(existingtUserGroupData?.find(g => g.id === state.team[i].id))
              continue;
            }
            promises.push(
              axiosCerebrum
                .put(`/api/groups/${state.team[i].id}/related?object_id=${response.data.id}&relationship=MEMBERS`)
                // eslint-disable-next-line
                .then(response => {
                  if (response.data) {
                    teams.push(response.data)
                  }
                })
                .catch(error => {
                  console.log(error)
                })
              )
          }
          // remove deleted team links
          if(existingtUserGroupData){
            for (let i = 0; i < existingtUserGroupData.length; i++) {
              if (!state.team.find(t => t.id === existingtUserGroupData[i].id)) {
                promises.push(
                  axiosCerebrum
                    .delete(`/api/groups/${existingtUserGroupData[i].id}/related?object_id=${response.data.id}&relationship=MEMBERS`)
                    // eslint-disable-next-line
                    .then(response => {
                      teams = teams.filter(t => t.id !== existingtUserGroupData[i].id)
                    })
                    .catch(error => {
                      console.log(error)
                    })
                )
              }
            }
          }

          await Promise.all(promises)
        }
        if(state.jobTypes.length>0){
          let promises = [];

          state.jobTypes.forEach(el=>{
            promises.push(
              axiosCerebrum
                .put(
                  `/api/collectioninstances/${el.id}/related`,undefined,{
                    params:{
                      object_id:response.data.id,
                      relationship:'MEMBERS'
                    }
                  }
                )
            )
          })

          await Promise.all(promises).catch(error=>{console.log(error)})

        }

        await axiosCerebrum
          .put(`/api/me/settings/${receiveEmailSettingID}`,{value:state.receiveEmail})
          .then(response=>{
            axiosCerebrum
              .get(`/api/me/settings`,{params:{per_page:50}})
              .then(response=>{
                localStorage.setItem('userSettings',JSON.stringify(response.data))
              })
              .catch(error=>{
                console.log(error)
              })
          })
          .catch(error=>{
            console.log(error)
          })

        setSubmitting(false)
        onLoginSuccess(data);
        if(state.isDarkMode){
          if(!localStorage.hasOwnProperty('dark')){
            localStorage.setItem('dark',true)
            window.open('/home?isOnboarded=true','_self')
          }else{
            history.push('/home?isOnboarded=true')
          }
        }else{
          if(localStorage.hasOwnProperty('dark')){
            localStorage.removeItem('dark')
            window.open('/home?isOnboarded=true','_self')
          }else{
            history.push('/home?isOnboarded=true')
          }
        }
      })
      .catch(error => {
        console.log(error, 'Error getting user data');
        setSubmitting(false)
        handleClose()
        sendAlert({ message: "Error occurred processing onboarding", type: 'error' })
      })
  }

  if(existingtUserGroupLoading){
    return <div className='flex items-center mt-20'><KadaLoadingSpinner variant={kLoadingSpinnerVariants.secondary}/></div>
  }

  return (
    <div className={classes.block}>
      {
        state.alias.length > 1 &&
        <div className={classes.banner}>
          <ErrorOutlineIcon style={{ marginRight: '1.5rem', color: '#FC642D', fontSize: 32 }} />
          <Typography style={{ fontSize: 16 }}>You have selected different Accounts to link. Please check this is correct before continuing.</Typography>
        </div>
      }
      <div className={classes.body}>
        <div className={classes.spacingDivs}>
          <Typography className={classes.headerText}>Team</Typography>
          {
            state.team.length > 0 &&
            <div style={{display:'flex',marginTop:-8,flexWrap:"wrap",width:560}}>
              {state.team?.map(el=>(
                <div className={classes.itemChip + ' ' + classes.targetItemChip}>
                  <Avatar align='center' className={classes.avatar}>{getInitials(el.name_txt)}</Avatar>
                  <Typography style={{color:theme.palette.background.main,marginRight:6}}>{el.name_txt}</Typography>
                </div>
              ))}
            </div>
          }
          {
            state.team.length === 0 &&
            <div style={{ display: 'flex', alignItems: 'center', width: 640 }}>
              <Typography color='textSecondary' className={classes.description}>No team selected</Typography>
            </div>
          }
        </div>
        <div className={classes.spacingDivs}>
          <Typography className={classes.headerText}>Job Types</Typography>
          {
            state.jobTypes.length > 0 &&
            <div style={{display:'flex',marginTop:-8,flexWrap:"wrap",width:560}}>
              {state.jobTypes?.map(el=>(
                <div className={classes.itemChip + ' ' + classes.targetItemChip}>
                  <Typography style={{color:theme.palette.background.main,marginRight:6,marginLeft:6}}>{el.name_txt}</Typography>
                </div>
              ))}
            </div>
          }
          {
            state.jobTypes.length === 0 &&
            <div style={{ display: 'flex', alignItems: 'center', width: 640 }}>
              <Typography color='textSecondary' className={classes.description}>No job type selected</Typography>
            </div>
          }
        </div>
        <Typography className={classes.headerText}>Linked Accounts</Typography>
        {
          state.alias.length === 0 && <Typography className={classes.description}>No accounts selected</Typography>
        }
        {
          state.alias.length > 0 &&
          <div style={{ display: 'flex', marginBottom: 8 }}>
            <Typography color='primary' style={{ fontSize: 12, flex: '0 1 300px', marginLeft: 56, paddingRight: 16 }}>NAME</Typography>
            <Typography color='primary' style={{ fontSize: 12, flex: '0 1 200px', paddingRight: 16 }}>ACCOUNT TYPE</Typography>
            <Typography color='primary' style={{ fontSize: 12, flex: '0 1 300px', paddingRight: 16 }}>SOURCE</Typography>
            <Typography color='primary' style={{ fontSize: 12, flex: '1 1 300px' }}>ALTERNATE SOURCE</Typography>
          </div>
        }
        {
          state.alias.map(el => (
            <div className={classes.columnRow}>
              {getIconComponent({ label: 'user', size: 24, colour: theme.palette.primaryText.light })}
              <Typography style={{ fontSize: 16, color: theme.palette.primaryText.main, flex: '0 1 300px', marginLeft: 16, paddingRight: 16, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                <KTooltip title={`${el.user.username}${el.user.username !== el.name ? ` (${el.name})` : ''}`} classes={{ tooltip: classes.tooltip }}>
                  <div className={classes.itemChip + ' ' + classes.targetItemChip}>
                    <span>{el.user.username}{el.user.username !== el.name ? ` (${el.name})` : ''}  </span>
                  </div>
                </KTooltip>
              </Typography>
              <Typography style={{ fontSize: 16, color: theme.palette.primaryText.main, flex: '0 1 200px', paddingRight: 16, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {el.user.account_type}
              </Typography>
              <Typography style={{ fontSize: 16, color: theme.palette.primaryText.main, flex: '0 1 300px', paddingRight: 16, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {el.source.name}
              </Typography>
              <Typography style={{ fontSize: 16, color: theme.palette.primaryText.main, flex: '1 1 300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {el.reference_sources.map(el => el.reference_source.name).join(', ')}
              </Typography>
            </div>
          ))
        }
      </div>
      <Dialog
        open={state.alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.modalPaper
        }}
      >
        <DialogTitle className={classes.alertTitle} id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              state.alias.length === 0 &&
              <div>
                <Typography color='textSecondary' className={classes.alertText}>{"You have selected no ID's to link to."}</Typography>
                <Typography color='textSecondary' className={classes.alertText}>{"You can add ID's at a later time by selecting 'Settings' from the 'User Options' menu item"}</Typography>
              </div>
            }
            {
              state.alias.length > 0 &&
              <div>
                <Typography color='textSecondary' className={classes.alertText}>You have chosen to link your account to{'\n\n'}{state.alias.map(el => `- ${el.user.username}${el.user.username !== el.name ? ` (${el.name})` : ''}`).join('\n')}</Typography>
                <Typography color='textSecondary' className={classes.alertText}>{"Make sure the above is correct before proceeding"}</Typography>
              </div>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            submitting?
            <div style={{width:60,display:'flex',justifyContent:'center'}}>
             <CircularProgress color='secondary' style={{width:16,height:16}} />
            </div>:
            <Button onClick={createUserOnboard} color='secondary' style={{ width: 60, }} >
              YES
            </Button>
          }

          <Button onClick={handleClose} color="primary" autoFocus style={{ width: 60 ,marginLeft:8}}>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

ConfirmProfile.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    //loggedIn: state.auth.logged_in
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onLoginSuccess: (data) => dispatch(actions.loginSuccess(data)),
    onLogoutSuccess: data => dispatch(actions.logoutSuccess(data))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(ConfirmProfile)));
