import axiosSolr from '../../../axios-solr'
import axiosCerebrum from '../../../axios-cerebrum'
import { templates } from '../InteractiveInput/Templates';
import { onClickResultItem, getLabelPlural } from '../../../utilities';
import { marked } from 'marked'
import DOMPurify from 'dompurify'

export const processDescriptionWithTerm = ({ text, onFinish, targetData, relatedTerms, forceUnlinkedTerm, forceLinkedTerm }) => {
  axiosSolr
    .post(
      '/solr/glossary/tag', text,
      {
        headers: {
          'Content-type': 'text/plain'
        }
      }
    )
    .then(async response => {
      let terms = response.data.response.docs.sort((a, b) => b.term.length - a.term.length);
      if (targetData?.parent?.category === 'GLOSSARY') {
        terms = terms.filter(t => t.term.toLowerCase() !== targetData.name.toLowerCase())
      }
      let newText = text;

      ////////////////////////////////
      // replace url links with expression to avoid pattern being broken when inserting widges
      let linkRegex = /\[[^[\]]*\]\(.*?\)/;
      let linkMarkdownRegex = /markdownLinkTmp@([0-9]+)@/
      let linkMatch = newText.match(linkRegex);
      let linkMatchIndex = 0;
      let linkMatchMap = {};

      while (linkMatch) {
        linkMatchMap[linkMatchIndex] = linkMatch[0];
        newText = newText.replace(linkMatch[0], `markdownLinkTmp@${linkMatchIndex}@`)
        linkMatchIndex++;
        linkMatch = newText.match(linkRegex);
      }

      ///////////////////////////
      // replace existing widgets
      let widgetRegex = /\$\$widget[^$$]+@\$\$/
      let widgetMarkdownRegex = /currentWidgetTmp@([0-9]+)@/
      let widgetMatch = newText.match(widgetRegex);
      let widgetMatchIndex = 0;
      let widgetMatchMap = {};

      while (widgetMatch) {
        widgetMatchMap[widgetMatchIndex] = widgetMatch[0];
        newText = newText.replace(widgetMatch[0], `currentWidgetTmp@${widgetMatchIndex}@`)
        widgetMatchIndex++;
        widgetMatch = newText.match(widgetRegex);
      }


      ////////////////////////////

      let unlinkedTerms = terms;
      let allRelatedTerms = relatedTerms || [];
      if (!relatedTerms) {
        await axiosCerebrum
          .get(
            `/api/${getLabelPlural(targetData.object.name)}/${targetData.id}/related/collections`,
            {
              params: {
                category: 'GLOSSARY',
                relationship: 'RELATED',
                per_page: 200
              }
            }
          )
          .then(response => {
            allRelatedTerms = response.data.items;
          })
      }

      if (forceLinkedTerm) {
        forceLinkedTerm.forEach(ft => {
          if (terms.find(t => t.term.toLowerCase() === ft.term.toLowerCase())) {
            return;
          }
          terms.push(ft)
        })
      }

      unlinkedTerms = unlinkedTerms.filter(u => !allRelatedTerms.find(l => l.name.toLowerCase() === u.term.toLowerCase()))
      let linkedManualCollections = []

      unlinkedTerms.forEach(t => {
        if (!newText.match(templates.termSuggestion.getReplacementRegex(t.term))) {
          linkedManualCollections.push(t)
        }
      })

      unlinkedTerms = unlinkedTerms.filter(u => !linkedManualCollections.find(l => l.term.toLowerCase() === u.term.toLowerCase()))
      if (forceUnlinkedTerm) {
        forceUnlinkedTerm.forEach(ft => {
          if (terms.find(t => t.term.toLowerCase() === ft.term.toLowerCase())) {
            return;
          }
          terms.push(ft)
          unlinkedTerms.push(ft)
        })
      }

      const sortTerms = (a, b) => {
        if (['collection', 'collection_instance'].includes(a.term.toLowerCase())) return -1;
        if (['collection', 'collection_instance'].includes(b.term.toLowerCase())) return 1;
        if (a.term.length !== b.term.length) return a.term.length - b.term.length;
      }

      terms.sort(sortTerms).forEach(t => {
        newText = newText.replace(
          templates.termSuggestion.getReplacementRegex(t.term),
          `${templates.termSuggestion.formatString({
            name: t.term,
            isMultiple: unlinkedTerms.filter(u => u.term.toLowerCase() === t.term.toLowerCase()).length > 1,
            isLinked: !unlinkedTerms.find(u => u.term.toLowerCase() === t.term.toLowerCase()),
            link: onClickResultItem({ item: t, id: t.id, label: 'collection_instance', isSimplifiedInstanceUrl: true, urlOnly: true })
          })}`
        )
      })

      // restore url links
      linkMatch = newText.match(linkMarkdownRegex);
      while (linkMatch) {
        newText = newText.replace(linkMatch[0], linkMatchMap[linkMatch[1]])
        linkMatch = newText.match(linkMarkdownRegex);
      }

      // restore existing widgets
      widgetMatch = newText.match(widgetMarkdownRegex);
      while (widgetMatch) {
        newText = newText.replace(widgetMatch[0], '$' + widgetMatchMap[widgetMatch[1]] + '$')
        widgetMatch = newText.match(widgetMarkdownRegex);
      }

      onFinish({ newText })
    })
    .catch(error => {
      console.log(error)
    })
}

export const sanitiseInput = ({value}) => {
  return DOMPurify.sanitize(marked.parse(value))
}


export const getPutPayload = ({ property, value, data }) => {
  let items = [{ field: property, value: value }];
  let label = data.object?.name?.toLowerCase()
  if(property==='description'){
    value = sanitiseInput({value, property})
  }
  if(!label && data.username)label = 'user'
  if(label==='issue' && data){
    items = [
      { field: 'name', value: data.name },
      { field: 'priority', value: data.priority },
      { field: 'workflow_status', value: data.workflow_status },
      { field: 'description', value: data.description },
    ].filter(el => el.field !== property);
    items.push({ field: property, value: value })
  }
  if (['content_child', 'workspace', 'content', 'data_pipeline', 'dataset', 'file', 'content_app', 'dataset_field', 'dataset_table', 'ml_model'].includes(label) && data) {
    items = [
      { field: 'external_url', value: data.external_url || '' },
      { field: 'location', value: data.location || '' },
      { field: 'type', value: data.type || '' },
      { field: 'description', value: data.description || '' },
    ].filter(el => el.field !== property)
    items.push({ field: property, value: value })
  }

  if (['table'].includes(label) && data) {
    items = [
      { field: 'replace_date', value: data.replace_date || null },
      { field: 'description', value: data.description || '' },
    ].filter(el => el.field !== property)
    items.push({ field: property, value: value })
  }

  if (['column', 'data_quality_test', 'host'].includes(label) && data) {
    items = [
      { field: 'description', value: data.description || '' },
    ].filter(el => el.field !== property)
    items.push({ field: property, value: value })
  }

  if (['query'].includes(label) && data) {
    items = [
      { field: 'name', value: data.name },
      { field: 'description', value: data.description || '' },
    ].filter(el => el.field !== property)
    items.push({ field: property, value: value })
  }
  if (['collection_instance'].includes(label) && data) {
    let isWarning = data.collection_txt?.toLowerCase() === 'warning notice' || data.parent?.name?.toLowerCase() === 'warning notice';
    items = [
      { field: 'name', value: data.name },
      { field: 'properties', value: data.properties || {} },
      { field: 'description', value: data.description || '' },
      { field: 'source_id', value: data.source_id },
      { field: 'hierarchy_parent_id', value: data.hierarchy_parent_id },
      isWarning ? { field: 'expiry', value: data.expiry || data.expiry.txt } : undefined,
    ].filter(el => el).filter(el => el.field !== property)
    items.push({ field: property, value: value })
  }
  if (['group'].includes(label) && data) {
    items = [
      { field: 'description', value: data.description || '' },
      { field: 'active_flag', value: data.active_flag },
      { field: 'parent_id', value: data.parent_id },
    ].filter(el => el.field !== property)
    items.push({ field: property, value: value })
  }
  if (['user'].includes(label) && data) {
    items = [
      { field: 'first_name', value: data.first_name },
      { field: 'last_name', value: data.last_name },
      { field: 'email', value: data.email },
      { field: 'active_flag', value: data.active_flag },
      { field: 'system_user_flag', value: data.system_user_flag },
      { field: 'description', value: data.description || '' },
      { field: 'username', value: data.username || '' }
    ].filter(el => el.field !== property)
    items.push({ field: property, value: value })
  }
  let payload = {};
  items.forEach(el => {
    payload[el.field] = el.value;
  })
  return payload;
}
