import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import axiosCerebrum from '../axios-cerebrum';

function useGetUserTeams({ userId, preventAuto = false }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const source = useRef(axios.CancelToken.source());

  const fetchUserTeams = useCallback(async () => {
    source.current.cancel();
    source.current = axios.CancelToken.source();

    setLoading(true);
    setError(false);
    try {
      const response = await axiosCerebrum.get(
        `/api/users/${userId}/related`,
        {
          params: {
            object_name: 'GROUP',
            relationship: "MEMBER_OF",
            per_page: 200
          },
          cancelToken: source.current.token
        }
      );
      setData(response.data.items);
    } catch (error) {
      setLoading(false);
      if (error.message) setError(error);
      console.log(error);
    }
    setLoading(false);
  // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (preventAuto) return;
    fetchUserTeams();
  // eslint-disable-next-line
  }, [userId, preventAuto, fetchUserTeams, source]);

  return {
    data,
    loading,
    error,
    fetchUserTeams
  }
}

export default useGetUserTeams;
