import React, { createContext, useReducer } from 'react';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/ImpactAssessmentV2/Body/Body';
import { getUserRoles } from '../../utilities'
import 'url-search-params-polyfill';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import { getAppNameByDirection } from '../../components/ImpactAssessmentV2/utils';

export const viewEnum = {
  history: 0,
  configuration: 1,
  result: 2
}

const initialState = {
  tabState: viewEnum.result,
}

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.value
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

export const IAContext = createContext()

function ImpactAssessmentV2(props) {
  const {
    history,
    sessionData,
    direction
  } = props;

  const roles = getUserRoles(sessionData.user_role)
  const [state, dispatch] = useReducer(reducer, initialState);


  let buttons = [];
  let title = getAppNameByDirection(direction);
  let subTitle = <span></span>

  return (
    <IAContext.Provider
      value={{
        state,
        dispatch,
        history,
        direction,
        roles
      }}
    >
      <ProfileLayout
        noPadding
        disableHeaderAutoHeight={true}
        header={(
          <ProfileHeader
            title={title}
            history={history}
            subtitle={subTitle}
            buttons={buttons}
            // description={description}
            minHeight={100}
            iconLabel={`ia_${direction}`}
          />
        )}
        body={
          <Body />
        }
      />
    </IAContext.Provider>
  )

}

export default ImpactAssessmentV2;
