import React from 'react';
import PropTypes from 'prop-types';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import AccessAdder from '../../UI/AccessAdder/AccessAdder'
import GlossaryAdder from '../../UI/GlossaryAdder/GlossaryAdder';
import SourceDescription from '../../UI/SourceDescription/SourceDescription';
import ProfileCodeSection from '../../UI/ProfileCodeSection/ProfileCodeSection';

const Details = props => {

  const {
    detailsData,
    state,
    dispatch,
    fetchList,
    history
  } = props;

  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/macros/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        label="macro"
        initialValue={state.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        fetchList={fetchList}
        collection="data"
        data={state.basicData}
        enableMarkDown={true}
        enableWidget={true}
        state={state}
      />

      <SourceDescription
        state={state}
        dispatch={dispatch}
      />

      <div style={{ marginTop: 60 }} >
        <GlossaryAdder
          state={state}
          dispatch={dispatch}
          history={history}
          disableModal
        />
      </div>

      <div style={{ marginTop: 24 }} >
        <AccessAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.accessModalOpen}
          setModalOpen={value=>dispatch({type:'set_access_modal_open',accessModalOpen:value})}
        />
      </div>

      <div className='mt-6'>
        <ProfileCodeSection
          codeArr={detailsData?.filter(el=>el.code).map(el=>el.code)}
          object={state.basicData}
        />
      </div>
	  </div>
  )
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  userName: PropTypes.string
}

export default Details;
