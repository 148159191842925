import React, { useEffect, useRef, useState } from 'react';
import { withStyles, Typography, CircularProgress,  InputBase, IconButton, Popper, ClickAwayListener, Checkbox, Paper, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosSolr from '../../../axios-solr';
import { getDispFields, getIconComponent, mapObjectName, onClickResultItem, sendMessage, toTitleCase } from '../../../utilities';
import ResultItem2 from '../../UI/SearchResults/ResultItem2';
import theme from '../../../theme';
import { globalListenerRef } from '../../../GlobalListenerRef';
import axiosCerebrum from '../../../axios-cerebrum';
import useAlert from '../../../hooks/useAlert';
import { lineageObjectsMap } from '../../UI/Lineage/utils';

const styles = theme => ({

  selectorHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:2,
    paddingLeft:16,
    marginTop:4,
    marginBottom:4
  },
  selector:{
    ...theme.components.selector,
    boxSizing:'border-box',
    width:'100%',
    '& div div':{
      padding:16
    },
    height:48
  },
  inputBase:{
    ...theme.components.inputBase,
    boxSizing:'border-box',
    width:'100%',
    height:48,
  },
  hideScroll:{
    ...theme.components.hideScroll,
  },

	listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },
	menuItem:{
		padding:'10px 10px 10px 16px',
		color:theme.palette.primaryText.main,
		'&:hover':{
      background: theme.palette.hovered.main
    }
	},
  checkbox:{
    paddingLeft:0,
  },
})

function RelatedList(props) {

  const {
    classes,
    history,
    type,
    object,
    shouldAddToCart,
    setShouldAddToCart,
    isCollection,
    state,
    dispatch,
    setDrawerOpen
  } = props;

  const searchTimeout = useRef()
  const [search, setSearch] = useState('')
  const [data, setData] = useState({})

  const [anchor, setAnchor] = useState(null)
  const [listActionOpen, setListActionOpen] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`ci-related-list-${object.id}`,
    isCancelledRef
  })

  useEffect(()=>{
    return () => {
      isCancelledRef.current = true
    }
  },[])

  const onAddToCart = ids => {
    if(ids.length===0){
      sendAlert({type:'error',message:'No items to add to Cart'})
      return;
    }
    axiosCerebrum
      .post(
        `/api/me/cart`,{
          object_ids:ids
        }
      )
      .then(response=>{
        sendAlert({type:'info',message:'Successfully added items to Cart'})
        sendMessage({reloadCart:true})
        setShouldAddToCart(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({type:'error',message:'Error occurred adding items to Cart'})
        setShouldAddToCart(false)
      })
  }

  const loadChildren = ({objectType=type, page=1, searchValue=search,isAddToCart, showInactive=state.mapShowDeleted}) => {

    if(!isAddToCart){
      setData({
        ...data,
        loading:true,
        error:false,
        items:page===1?[]:data.items
      })
    }
    let url = `/api/collectioninstances/${object.id}/related`
    let param = {
      object_name:objectType,
    }
    if(isCollection){
      url = `/api/collectioninstances/${object.id}/related/collections`;
      param = {
        parent_name: objectType,
      }
    }
    axiosCerebrum
      .get(
        url,{
          params:{
            ...param,
            relationship: 'MEMBERS,MEMBERS_AUTO,RELATED,RELATES_TO,MEMBER_OF,CLASSIFIES',
            per_page:isAddToCart?500:10,
            page:isAddToCart?1:page,
            object_active_flag:showInactive?undefined:true,
            'search.name':searchValue
          }
        }
      )
      .then(response=>{
        if(isAddToCart){
          onAddToCart(response.data.items.map(el=>el.id))
          return;
        }

        if(response.data.items.length===0){
          setData(response.data)
          return;
        }

        axiosSolr
          .get(
            `/solr/search/select`,{
              params:{
                q:"*",
                fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
                rows:response.data.items.length
              }
            }
          )
          .then(solrRes=>{
            let dedupedItems = []
            response.data.items.forEach(el=>{
              if(!dedupedItems.find(v=>v.id===el.id)){
                dedupedItems.push(el)
              }
            })
            if(page!==1){
              dedupedItems = dedupedItems.filter((v)=>{
                return !data.items?.find(el=>el.id===v.id)
              })
            }
            let totalNumberOffset = response.data.items.length - dedupedItems.length;

            setData({
              ...response.data,
              total:(data?.total || response.data.total) - totalNumberOffset,
              items:[
                ...(page===1?[]:(data?.items || [])),
                ...dedupedItems.map(el=>{
                  let solrItem = solrRes.data.response.docs.find(solrEl=>solrEl.id===el.id);
                  return {
                    ...el,
                    ...(solrItem||{})
                  }
                }
              )]
            })
          })
          .catch(error=>{
            console.log(error)
            setData({error:true})
          })
      })
      .catch(error=>{
        console.log(error)
        if(isAddToCart){
          sendAlert({type:'error',message:'Error occurred adding items to Cart'})
          setShouldAddToCart(false)
          return;
        };
        setData({error:true})
      })
  }

  useEffect(()=>{
    loadChildren({objectType:type})
  // eslint-disable-next-line
  },[type])

  useEffect(()=>{
    if(!data.items || data.loading)return;
    let container = document.getElementById(`map-related-list`);
    if(!container)return;
    if(container.scrollHeight!==container.clientHeight)return;
    if(data.page<data.pages){
      loadChildren({page:data.page+1});
    }
  // eslint-disable-next-line
  },[data])

  useEffect(()=>{
    if(!shouldAddToCart)return;
    loadChildren({isAddToCart:true})
  // eslint-disable-next-line
  },[shouldAddToCart])

  useEffect(()=>{
    let container = document.getElementById(`map-related-list`);
    container.removeEventListener('scroll',globalListenerRef.miniProfileScrollListener);
    if(!container)return;
    if(!data.items || data.loading)return;
    const onScroll = (event) => {
      if(event.target.scrollTop>=event.target.scrollHeight-event.target.clientHeight-10){
        if(data && !data.loading && data.page<data.pages){
          loadChildren({page:data.page+1});
        }
      }
    }
    globalListenerRef.miniProfileScrollListener = onScroll;
    container.addEventListener("scroll", globalListenerRef.miniProfileScrollListener);
    return (()=>{container.removeEventListener('scroll',globalListenerRef.miniProfileScrollListener);})
  // eslint-disable-next-line
  },[data])

  const onSearchChange = (value) => {
    setSearch(value)
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(()=>{
      loadChildren({page:1,searchValue:value})
    },300)
  }

  const onClickShowDeleted = (value) => {
    dispatch({
      type:"set_map_show_deleted",
      mapShowDeleted:value
    })
    loadChildren({page:1,showInactive:value})
  }

  const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

  return (
    <div style={{flexGrow:1,overflow:'auto',marginBottom:24,paddingLeft:24}} className={classes.hideScroll} id="map-related-list">
      <div style={{zIndex:10,background:theme.palette.background.main,position:'sticky',top:0,paddingBottom:24,}}>
        <Typography style={{fontSize:20,color:theme.palette.header.main,paddingBottom:isCollection?12:0}}>
          {data.total?data.total+' ':''}{mapObjectName(type).replace(/_/g,' ')}(S) LINKED TO {object.name.toUpperCase()}
        </Typography>
        {
          !isCollection &&
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light,paddingBottom:12}}>Select an item to see its lineage</Typography>
        }
        <div style={{display:"flex",alignItems:"center"}}>
          <InputBase
            placeholder={`Search ${toTitleCase(mapObjectName(type).replace(/_/g,' '))}s`}
            className={classes.inputBase}
            value={search}
            onChange={event=>{onSearchChange(event.target.value)}}
            endAdornment={
              <IconButton
                disabled={search===''}
                onClick={()=>onSearchChange('')}
                style={{width:32,height:32,marginRight:6}}
              >
                {getIconComponent({label:search===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
              </IconButton>
            }
          />
          <div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onListActionClick}>
						{getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
					</div>
					<Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
						<ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
							<Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
								<Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
								<MenuItem onClick={()=>{onClickShowDeleted(!state.mapShowDeleted)}} className={classes.menuItem} >
                  <Checkbox key={state.mapShowDeleted} className={classes.checkbox} color='primary' checked={!state.mapShowDeleted}/>
                  <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                </MenuItem>
							</Paper>
						</ClickAwayListener>
					</Popper>
        </div>
      </div>
      {
        data.items?.map((item,index)=>(
          <ResultItem2
            item={item}
            key={item.id}
            dispBody={getDispFields(item,'dispBody')}
            dispSubtitle={getDispFields(item,'dispSubtitle')}
            dispTitle={getDispFields(item,'dispTitle')}
            label={item.object_type_txt}
            showIcon
            showUnderline
            showSimplifiedTrustIcon
            onClick={() => {
              if(isCollection || !lineageObjectsMap[item.type] ){
                onClickResultItem({id:item.id,label:item.object_type_txt,history:history,item:item,newWindow:true})
                return;
              }
              dispatch({
                type:"set_lineage_selected_item",
                lineageSelectedItem:item
              })
              setDrawerOpen(false)
            }}
          />
        ))
      }
      {
        data.total===0 &&
        <Typography>No related {mapObjectName(type).replace(/_/g,' ')}s found</Typography>
      }
      {
        data.loading &&
        <div style={{display:'flex',justifyContent:'center',marginTop:6}}>
          <CircularProgress color='secondary'/>
        </div>
      }
      {
        data.error &&
        <Typography>Error occurred loading items</Typography>
      }
    </div>
  )
}

RelatedList.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionData: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withStyles(styles)(RelatedList);
