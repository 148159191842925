import React  from 'react';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import GlossaryAdder from '../../UI/GlossaryAdder/GlossaryAdder';
import AdditionalPropertyAdder from '../../UI/AdditionalPropertyAdder/AdditionalPropertyAdder'
import SourceDescription from '../../UI/SourceDescription/SourceDescription';
import ProfileCodeSection from '../../UI/ProfileCodeSection/ProfileCodeSection';

const Details = props => {

  const {
    state,
    fetchList,
    history,
    dispatch,
  } = props;

  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/datasetfields/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        data={state.basicData}
        label="dataset_field"
        initialValue={state.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        fetchList={fetchList}
        collection="content"
        enableMarkDown={true}
        enableWidget={true}
        state={state}
      />

      <SourceDescription
        state={state}
        dispatch={dispatch}
      />

      <div style={{ marginTop: 60 }} >
        <GlossaryAdder
          state={state}
          dispatch={dispatch}
          history={history}
          disableModal
        />
      </div>

      <div style={{ marginTop: 24 }} >
        <AdditionalPropertyAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.additionalPropertiesModalOpen}
          setModalOpen={value=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:value})}
        />
      </div>

      {
        state.basicData.code &&
        <div className='mt-6'>
          <ProfileCodeSection
            code={state.basicData.code}
            object={state.basicData}
          />
        </div>
      }

	  </div>
  )
}


export default Details;
