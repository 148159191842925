import React, {useState, useEffect} from 'react';
import { Button } from '@material-ui/core';
import {toTitleCase, getInitials, getIconComponent, formatNumber} from '../../../utilities';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../../UI/ContextMenu/ContextMenu'
import axiosSolr from '../../../axios-solr';
import { KadaAvatar, KadaBadge } from 'kada-component-library';
import DQModal from '../../UI/DQModal/DQModal';
import useGetUserTeams from '../../../hooks/useGetUserTeams';

function SideBar(props) {
  const {
    state,
    history,
    sessionData
  } = props;

  const [dqModalOpen, setDqModalOpen] = useState(false)

  const [teamExpanded, setTeamExpanded] = useState(false)

  const [myItems, setMyItems] = useState({});

  let userData = sessionData;

  const {
    data: groupData
  } = useGetUserTeams({ userId: sessionData.id });

  const loadMyItems = async () => {
    setMyItems({loading:true})
    let items = []
    await axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:"*",
            fq:"object_type_srt:ISSUE",
            rows:0,
            'json.facet':{
              assignedToMe:{
                type:'query',
                q:`assignee_msrt:"${sessionData.name}"`,
              },
              createdByMe:{
                type:'query',
                q:`created_by_msrt:"${sessionData.name}"`,
              },
            }
          }
        }
      )
      .then(response=>{
        if(response.data.facets.assignedToMe.count>0)items.push({text:`Assigned issues`,value:response.data.facets.assignedToMe.count,onClick:()=>history.push(`/issue_board?presetFilter=assignedToMe`)})
        if(response.data.facets.createdByMe.count>0)items.push({text:`Reported issues`,value:response.data.facets.createdByMe.count,onClick:()=>history.push(`/issue_board?presetFilter=createdByMe`)})
      })
      .catch(error=>{
        console.log(error);
        setMyItems({error:true})
      })

    setMyItems({data:items})
  }

  useEffect(()=>{
    if(!myItems.data){
      loadMyItems()
    }
  // eslint-disable-next-line
  },[])

  return (
    <div className={"mb-8 flex flex-col"}>
      <div style={{marginLeft:20}}>
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'max-content',maxWidth:'100%'}}>
          <KadaAvatar
            size={80}
            className="mb-6"
            text={getInitials(userData.name)}
          />
          <h1 className='text-(--color-header-text)' style={{whiteSpace:'pre-wrap',wordBreak:'break-all'}} >{toTitleCase(userData.name)}</h1>
        </div>
        <div className="divider mb-6 mt-10 w-30"></div>
        <h2 className='text-(--color-header-text) mb-2'>Team</h2>
        {
          groupData && groupData.length>0 && (teamExpanded?groupData:groupData.slice(0,1)).map(el=>(
            <div className="flex items-center p-1.5 max-w-[188px] w-max cursor-pointer border border-primary rounded mb-2 hover:bg-(--color-base-200)" data-test-classname="team-chip" onClick={()=>history.push(`/profile/group/${el.id}`)}>
              {getIconComponent({label:'group',size:24,colour:'var(--color-primary)'})}
              <p
                className='text-primary truncate ml-3'
              >
                {el.name}
              </p>
            </div>
          ))
        }
        {
           groupData && groupData.length>1 &&
          <Button color='primary' data-test-id="team-see-more-button" onClick={()=>setTeamExpanded(!teamExpanded)}>
            {teamExpanded?'SEE LESS':'SEE MORE'}
          </Button>
        }
        {
          (!groupData || groupData.length===0) &&
          <div className="flex items-center p-1.5 max-w-[188px] w-max cursor-pointer border border-primary rounded mb-2 hover:bg-[--color-base-200]" data-test-id="add-team-button" onClick={()=>history.push(`/settings`)}>
            {getIconComponent({label:'info',size:24,colour:'var(--color-primary)'})}
            <p
              className="ml-3"
            >
              Add your Team
            </p>
          </div>
        }

        <div style={{marginTop:48}}>
          <h2 className='text-(--color-header-text) mb-2'>My actions</h2>
          {
            myItems?.data?.map(el=>(
              <div data-test-classname="my-action-item" className={"flex justify-between items-center overflow-hidden h-12 px-4 border-b border-(--color-base-border) " + (el.onClick ? "cursor-pointer hover:bg-(--color-base-200)" : "")} onClick={el.onClick}>
                <p>{el.text}</p>
                <KadaBadge
                  text={formatNumber(el.value)}
                />
              </div>
            ))
          }
          <div data-test-classname="my-action-item" className="flex justify-between items-center overflow-hidden h-12 px-4 border-b border-(--color-base-border) cursor-pointer hover:bg-(--color-base-200)" onClick={()=>{setDqModalOpen(true)}}>
            <p>Create an issue</p>
          </div>
        </div>

        { state.metricsData && state.metricsData.filter(el=>el.value!==0).length!==0 && <h2 className="text-(--color-header-text) mt-12">Metrics</h2>}
        {
          state.metricsData && state.metricsData.filter(el=>el.value!==0).map(el=>(
            <div>
              <ContextMenuTrigger id={el.name+'_home_metrics'}>
                <div data-test-classname="home-metrics" className={"flex justify-between items-center overflow-hidden h-12 px-4 border-b border-(--color-base-border) " + (el.value!==0 ? "cursor-pointer hover:bg-(--color-base-200)" : '')} onClick={el.value!==0?el.onClick:undefined}>
                  <p className={'mr-3 flex-grow truncate'}>{el.name}</p>
                  <KadaBadge
                    text={formatNumber(el.value)}
                  />
                </div>
              </ContextMenuTrigger>
              <ContextMenu id={el.name+'_home_metrics'}>
                <CustomMenu
                  itemUrl={el.url}
                  actions={[
                    'open_new_tab'
                  ]}
                />
              </ContextMenu>
            </div>
          ))
        }

      </div>
      <DQModal
        object={{}}
        dqModalOpen={dqModalOpen}
        setDqModalOpen={setDqModalOpen}
      />
    </div>
  )
}

export default SideBar;
