import React, {useState, useRef, useEffect} from 'react';
import { withTheme, withStyles, Typography, Popper, Paper, ClickAwayListener,MenuList,MenuItem, Divider, Checkbox } from '@material-ui/core';
import { getIconComponent } from '../../../utilities';
import DropdownIcon from '@material-ui/icons/ArrowDropDown'
import KTooltip from '../KTooltip/KTooltip';
import PropTypes from 'prop-types';

const styles = theme => ({
  container: {
    margin: '8px 0px 8px 16px',
    height: 38,
    minWidth:80,
    display:'flex',
    alignItems:'center',
    borderRadius:4,
    border:'1px solid',
    borderColor:theme.palette.primary.main,
    '&:hover':{
      background:theme.palette.hovered.main,
    }
  },
  hoveredButton:{
    '&:hover':{
      background:theme.palette.primary.main,
    }
  },
  disabled:{
    borderColor:theme.palette.primaryText.light,
    '&:hover':{
      background:theme.palette.background.main,
    },
    cursor:'default',
  },
  mainButton:{
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    paddingLeft:8,
    paddingRight:8,
    height:'100%',
  },
  dropdownIconContainer:{
    // borderLeft:'1px solid',
    // borderColor:theme.palette.primary.main,
    width:36,
    height:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    cursor:'pointer',
  },
  dropdownIcon:{
    width:24,
    height:24,
  },
  listContainer:{
    padding:0,
  },
  menuItem:{
    borderRadius:4,
    '&:hover':{
      background: theme.palette.buttonHovered.main
    }
  },
  title:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primary.main,
    marginBottom:8,
    marginLeft:16,
    marginRight:16,
    marginTop:8
  },
  tooltip:{
    fontSize:13.75
  },
  hidden:{
    display:'none'
  }
})

function DropdownButton(props) {

  const {
    classes,
    theme,
    width,
    iconLabel,
    disabled,
    text,
    optionList=[],
    title,
    iconOnly,
    iconColour,
    tooltip,
    height,
    noMargin,
    isInfoBoxButton,
    isRounded,
    hideSectionTitle
  } = props;

  const parentRef = useRef()
  const [minWidth, setMinWidth] = useState(0);
  const [anchor, setAnchor] = useState()
  const [listOpen, setListOpen] = useState(false);

  const [hovered, setHovered] = useState(false)
  // optionList structure:
  // [
  //   {
  //     iconLabel: '...',
  //     text:'...',
  //     onClick: function()
  //   }
  // ]

  useEffect(()=>{
    if(!parentRef || !parentRef.current)return;
    setMinWidth(parentRef.current.getBoundingClientRect().width)
  },[parentRef])

  const onDropdownClick = (event) => {
    if(disabled )return;
    event.stopPropagation();
    setAnchor(event.currentTarget);
    if(!listOpen)setListOpen(true);
  }

  const onChildClick = func => {
    func();
    setListOpen(false);
  }

  const getOptionListItem = option => {
    return (
      <KTooltip title={option.tooltip}>
        <div>
          <MenuItem disabled={option.disabled} data-test-id={`dropdown-button-option-${(option.text||'').toLowerCase().replace(/[\s_]/g,'-')}`} onClick={()=>onChildClick(option.onClick)} className={classes.menuItem} >
            <div style={{display:'flex',alignItems:'center'}}>
              {
                option.isCheckbox?
                <Checkbox style={{paddingLeft:0,marginRight:12}} checked={option.checked} color='primary'/>
                :
                option.iconLabel && !iconOnly?
                  <div style={{width:24,height:24,marginRight:12}}>
                    {getIconComponent({label:option.iconLabel,size:24,colour:theme.palette.primary.main})}
                  </div>
                  :
                  <></>
              }
              <Typography style={{fontSize:!iconOnly?13.75:16, color:theme.palette.primaryText.main }}>{option.text}</Typography>
            </div>
          </MenuItem>
        </div>
      </KTooltip>
    )
  }

  let mainElementColour = hovered?theme.palette.background.main:theme.palette.primary.main;
  if(isInfoBoxButton)mainElementColour  = 'var(--color-success)'
  if(disabled)mainElementColour = theme.palette.primaryText.light

  if(optionList.filter(el=>el).length===0)return <></>

  return (
      <div>
        <KTooltip  classes={{tooltip:tooltip?classes.tooltip:classes.hidden}} title={tooltip}>
          <div
            className={classes.container + (disabled?' '+classes.disabled:'')}
            data-test-id={`dropdown-button-${(text||tooltip||'').toLowerCase().replace(/[\s_]/g,'-')}`}
            ref={parentRef}
            onClick={(e)=>{
              if(disabled)return;
              onDropdownClick(e)
            }}
            onMouseEnter={()=>!isInfoBoxButton && setHovered(true)}
            onMouseLeave={()=>setHovered(false)}
            style={{
              width:width,
              border:iconOnly?'none':(isInfoBoxButton && !disabled?`1px solid var(--color-success)`:undefined),
              minWidth:iconOnly?0:undefined,
              backgroundColor:hovered && !iconOnly?theme.palette.primary.main:undefined,
              margin:noMargin?0:undefined,
              height,
              borderRadius:isRounded?'50%':''
            }}
          >
            <div className={classes.mainButton + (iconOnly || isInfoBoxButton?'':` ${classes.hoveredButton}`)} style={{cursor:disabled?'default':'pointer'}}>
              {iconOnly && iconLabel && getIconComponent({ label: iconLabel, size: '1.25rem', colour: iconColour || mainElementColour })}
              {text && !iconOnly && <span style={{ marginLeft: 8, color:mainElementColour, fontSize:13.75 }}>{text}</span>}
            </div>
            {
              !iconOnly &&
              <div
                onClick={onDropdownClick}
                className={classes.dropdownIconContainer}
                style={
                  hovered?
                  {backgroundColor:theme.palette.primary.main}:
                  undefined
                }
              >
                <DropdownIcon className={classes.dropdownIcon} style={{color:mainElementColour}}/>
              </div>
            }
          </div>
        </KTooltip>

        <Popper open={listOpen} anchorEl={anchor} placement={iconOnly?'bottom':'bottom-end'}>
          <ClickAwayListener onClickAway={()=>setTimeout(()=>setListOpen(false))}>
            <Paper style={{marginTop:8,marginRight:-2,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
              <MenuList className={classes.listContainer} style={{minWidth:minWidth}}>
                {
                  !title && optionList.filter(el=>el).map((option,index)=>(
                    <div>
                      {getOptionListItem(option)}
                      {index!==optionList.length-1 && <Divider style={{marginLeft:16, background:theme.palette.listItemDivider.main}}/>}
                    </div>

                  ))
                }
                {
                  title && title.filter(t=>optionList.filter(el=>el).find(el=>el.group===t)).map((t,i)=>(
                    <div>
                      {
                        hideSectionTitle?
                        <>
                          {
                            i!==0 && <Divider style={{background:theme.palette.listItemDivider.main, marginTop: 8, marginBottom: 8}}/>
                          }
                        </>:
                        <Typography className={classes.title}>{t}</Typography>
                      }
                      {optionList.filter(el=>el && el.group===t).map((option,index)=>(
                        <div>
                          {getOptionListItem(option)}
                        </div>
                      ))}
                    </div>
                  ))
                }
              </MenuList>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>

  )
}

DropdownButton.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.number,
  iconLabel: PropTypes.string,
  text: PropTypes.string,
  groups: PropTypes.array,
  optionList: PropTypes.array,
  title: PropTypes.array,
  iconOnly: PropTypes.bool,
  iconColour: PropTypes.string,
  tooltip: PropTypes.string,
  noMargin: PropTypes.bool,
  isInfoBoxButton: PropTypes.bool,
  height: PropTypes.number,
  disabled: PropTypes.bool,
  hideSectionTitle: PropTypes.bool,
};

export default withTheme()(withStyles(styles)(DropdownButton));
