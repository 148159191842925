import React  from 'react';
import PropTypes from 'prop-types';
import { getInitials, onClickResultItem } from '../../../utilities';
import { KadaAvatar, KadaLoadingSpinner, kLoadingSpinnerVariants } from 'kada-component-library';
import { onOpenContextHolder } from '../../UI/ContextMenu/utils';
import ContextMenuHolder from '../../UI/ContextMenu/ContextMenuHolder';
import CustomMenu from '../../UI/ContextMenu/ContextMenu';

const JobType = props => {

  const {
    state,
  } = props;

  return (
    <div id="profile-access-role-section" tabIndex={0} className="collapse collapse-arrow mt-6">
      <input
        type="checkbox"
      />
      <div className="collapse-title flex items-center">
        <div>
          <span className="text-sm text-(--color-header-text)">
            JOB FAMILY
          </span>
          <p className='!text-xs text-(--color-light-text)'>
            Job family this user belongs to
          </p>
        </div>
      </div>
      <div className="collapse-content">
        {
          state.jobType?.length === 0 &&
          <p>
            No teams found
          </p>
        }
        {
          state.jobTypeLoading &&
          <KadaLoadingSpinner variant={kLoadingSpinnerVariants.secondary} />
        }
        {
          state.jobTypeError &&
          <p>
            Error occurred loading teams
          </p>
        }
        {
          state.jobType?.length > 0 &&
          <table className="table" id="issue-table" data-test-id="teams-table">
            <tbody>
              {
                state.jobType?.map((item, index) => (
                  <>
                    <tr
                      key={item.id}
                      className='hover:bg-base-200'
                      onContextMenu={e=>{
                        e.preventDefault()
                        onOpenContextHolder({
                          x: e.clientX,
                          y: e.clientY,
                          id: item.id
                        })
                      }}
                    >
                      <td class="w-6 p-0">
                        <KadaAvatar
                          text={getInitials(item.name)}
                        />
                      </td>
                      <td>
                        <a href={onClickResultItem({ item, id: item.id, label:'collection_instance', urlOnly: true })} className="underline">
                          {item.name}
                        </a>
                      </td>
                    </tr>
                    <ContextMenuHolder
                      popperId={item.id}
                    >
                      <CustomMenu
                        item={item}
                        actions={[
                          'open_new_tab'
                        ]}
                      />
                    </ContextMenuHolder>
                  </>
                ))
              }
            </tbody>
          </table>
        }
      </div>
    </div>
  )
}

JobType.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default JobType;
