import React from 'react';
import HistoryList from './HistoryList';
import ImportModal from './ImportModal';
import { KadaBtn, kButtonVariants } from 'kada-component-library';

function Import(props) {

  const {
    history,
    state,
    dispatch,
    sessionData
  } = props;


  return (
    <div>
      <div className={'kada-info-box'}>
        <h3 className={'mb-2'}>About Importing Collections</h3>
        <div style={{display:'flex',alignItems:'flex-start'}}>
          <p className={'kada-info-box-description'}>
            The import function lets you perform a bulk edit on your collection instance via using and importing an excel file. Each collection will require its own bulk edit template. Make sure you are using the latest template otherwise the import will fail.
            {'\n\n'}
            There are two import options:
            {'\n'}
            <ul style={{paddingInlineStart:16}}>
              <li>
                <span style={{fontWeight:700}}>Update collection instances:</span> Use this option if you are making some general changes to the collection (e.g. updating properties, description and changing owners/stewards)
              </li>
              <li>
                <span style={{fontWeight:700}}>Delete & reload collection:</span> This option is designed for the scenario where you'd like to delete all metadata and linkages in the collection and start from a clean slate. After deleting everything, the collection will be re-loaded using the Bulk Edit Template that is uploaded.
              </li>
            </ul>
          </p>
          <div style={{textAlign:'-webkit-right',flexShrink:0}}>
            <div style={{width:'max-content'}}>
              <KadaBtn text="IMPORT BULK EDIT FILE" variant={kButtonVariants.successContained}  onClick={()=>dispatch({type:"set_import_modal_open",importModalOpen:true})}>
              </KadaBtn>
            </div>
          </div>

        </div>
      </div>
      <HistoryList sessionData={sessionData} history={history} state={state} dispatch={dispatch}/>

      <ImportModal
        modalOpen={state.importModalOpen}
        dispatch={dispatch}
        state={state}
        history={history}
        setModalOpen={value=>dispatch({type:'set_import_modal_open',importModalOpen:value})}
      />
    </div>
  )
}

export default Import;
