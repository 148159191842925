import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router'
import { withTheme, withStyles, Typography, Paper, MenuItem, ClickAwayListener, Select, InputBase, Button } from '@material-ui/core';
import { getIconComponent, copyToClipboard, sendMessage } from '../../../utilities'
import axiosSolr from '../../../axios-solr';
import axiosCerebrum from '../../../axios-cerebrum';
import ModalAlert from '../ModalAlert/ModalAlert';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {
    borderRadius:4,
    minWidth:300,
    padding:'8px 0',
    zIndex:29,
  },
  listText:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    marginLeft:19,
    paddingRight:8,
    pointerEvents:'none'
  },
  menuItem:{
    paddingTop:8,
    paddingBottom:8,
    borderRadius:4
  },
  termInputBase:{
    ...theme.components.inputBase,
    height:40,
    width:'100%',
    boxSizing:'border-box',
    caretColor:theme.palette.primaryText.main,
    '& input':{
      paddingLeft:8,
      caretColor:theme.palette.primaryText.main,
    }
  },
  termInputField:{
    ...theme.components.inputBase,
    caretColor:theme.palette.primaryText.main,
    width:'100%',
    '& textarea':{
      paddingLeft:8,
      caretColor:theme.palette.primaryText.main,
    }
  },
  inputContainer:{
    display:'flex',
    alignItems:'center',
    marginBottom:24,
  },
  inputTitle:{
    width:60,
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    marginRight:32,
    pointerEvents:'none'
  },
  selector:{
    ...theme.components.selector,
    width:'100%'
  },

  highlightInput:{
    border:`2px solid ${theme.palette.error.main}`
  }
})

function TextMenu(props) {

  const {
    classes,
    theme,
    object,
    setOpen,
    anchor={x:0,y:0},
    text='',
    onPaste
  } = props;

  // let sessionData = useStore().getState().auth.session_user;

  const [createTermModalOpen, setCreateTermModalOpen] = useState(false)
  const [glossaryList, setGlossaryList] = useState(null)
  const [selectedGlossary, setSelectedGlossary] = useState('none')
  const [termName, setTermName] = useState('')
  const [termDescription, setTermDescription] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  const getGlossary = () => {
    const loadData = ({prevData = []}) => {
      axiosSolr
        .get(
          '/solr/search/select', {
            params:{
              q:'*',
              fq:'object_type_srt:COLLECTION AND collection_type_srt:GLOSSARY',
              rows:100,
              start:prevData.length
            }
          }
        )
        .then(response=>{
          let data = [...prevData, ...response.data.response.docs];
          if(data.length>=response.data.response.numFound){
            setGlossaryList(data)
          }else{
            loadData({prevData:data})
          }
        })
        .catch(error=>{
          console.log(error)
        })
    }

    loadData({})

  }

  const onButtonClicked = () => {
    setOpen(false)
  }

  const onAddGlossary = () => {
    let payload = {}
    payload.collection_id = selectedGlossary;
    payload.description = termDescription;
    payload.name = termName;
    payload.properties = {};
    payload.source_id = 1000;

    axiosCerebrum
      .post(
        '/api/collectioninstances',
        payload
      )
      .then(response=>{
        onButtonClicked();
        setSelectedGlossary('none')
        setTermName('')
        setTermDescription('')

        if(object){
          axiosCerebrum
          .put(`/api/collectioninstances/${response.data.id}/related?relationship=RELATES_TO&object_id=${object.id}`)
          .then(()=>{
            sendAlert({
              message:'Term successfully added and linked',
              type:'info'
            })
            sendMessage({
              reload_related_terms:true,
            })
          })
          .catch(error=>{
            sendMessage({
              reload_related_terms:true,
            })
            console.log(error)
            sendAlert({
              message:'Term successfully added but failed to link, please try manually link from term suggestion section',
              type:'info'
            })
          })
        }else{
          sendAlert({
            message:'Term successfully added',
            type:'info'
          })
        }

      })
      .catch(error=>{
        console.log(error)
        let msg = 'Error occurred adding the Term, please try again'
        if(error.response && error.response.status && error.response.status===403){
          msg = 'You do not have permissions to add a Term to this Glossary'
        }
        if(error.response && error.response.status && error.response.status===409){
          msg = 'Your new Term exists in this glossary'
        }
        setAlertMessage(msg)
        setAlertOpen(true)
      })
  }

  useEffect(()=>{
    if(createTermModalOpen && !glossaryList){
      getGlossary()
    }
  // eslint-disable-next-line
  },[createTermModalOpen])

  useEffect(()=>{
    setTermName(text)
  },[text])

  let modalStyle = {background:theme.palette.contextMenuBackground.main}
  if(createTermModalOpen){
    modalStyle={background:theme.palette.background.main, border:`1px solid ${theme.palette.border.main}`}
  }

  return (
    <ClickAwayListener onClickAway={(event)=>!createTermModalOpen && event.button!==2 && setOpen(false)}>
      <div className={'text-context-menu'} onContextMenu={event=>event.stopPropagation()}  style={{position:'absolute',left:anchor.x, top:anchor.y,zIndex:29}}>
        <Paper onContextMenu={event=>event.stopPropagation()} className={classes.root} style={{ ...modalStyle}}>
          {
            !createTermModalOpen &&
            <>
              <MenuItem
                className={classes.menuItem}
                button
                onClick={(event)=>{
                  event.preventDefault();
                  event.stopPropagation();
                  copyToClipboard(text)
                  onButtonClicked()
                }}
              >
                {getIconComponent({label:'copy',size:24,colour:theme.palette.primaryText.dark})}
                <Typography className={classes.listText}>Copy</Typography>
              </MenuItem>
              {
                onPaste &&
                <MenuItem
                  className={classes.menuItem}
                  button
                  onClick={(event)=>{
                    event.preventDefault();
                    event.stopPropagation();
                    onPaste()
                    onButtonClicked()
                  }}
                >
                  {getIconComponent({label:'paste',size:24,colour:theme.palette.primaryText.dark})}
                  <Typography className={classes.listText}>Paste</Typography>
                </MenuItem>
              }
              <MenuItem
                className={classes.menuItem}
                button
                onClick={(event)=>{
                  event.preventDefault();
                  event.stopPropagation();
                  setCreateTermModalOpen(true)
                  // onButtonClicked()
                }}
              >
                {getIconComponent({label:'glossary',size:24,colour:theme.palette.primaryText.dark})}
                <Typography className={classes.listText}>Create a glossary term for "{text.length>30?text.slice(0,30)+'...':text}"</Typography>
              </MenuItem>
            </>
          }
          {
            createTermModalOpen &&
            <div  style={{width:568,padding:'16px 24px',cursor:'default'}}>
              <Typography style={{fontSize:16,color:theme.palette.header.main,marginBottom:24,pointerEvents:'none'}}>Add a new Term</Typography>
              <div className={classes.inputContainer}>
                <Typography className={classes.inputTitle}>
                  Glossary
                </Typography>
                <div style={{flexGrow:1}}>
                  <Select
                    className={classes.selector + (selectedGlossary==='none'?' '+classes.highlightInput:'')}
                    onClick={event=>{event.stopPropagation()}}
                    value={selectedGlossary}
                    onChange={event=>{setSelectedGlossary(event.target.value)}}
                    disableUnderline
                    MenuProps={{
                      id:'add-ci-modal-menu',
                      className:'text-context-menu'
                    }}
                  >
                    <MenuItem  value='none'>Select a Glossary</MenuItem>
                    {
                      glossaryList && glossaryList.map(el=>(
                        <MenuItem key={el.id} value={el.id}>{el.name_txt}</MenuItem>
                      ))
                    }
                  </Select>
                </div>
              </div>

              <div className={classes.inputContainer}>
                <Typography className={classes.inputTitle}>
                  Term
                </Typography>
                <div style={{flexGrow:1}}>
                  <InputBase
                    className={classes.termInputBase  + (termName.trim()===''?' '+classes.highlightInput:'')}
                    value={termName}
                    onChange={event=>{setTermName(event.target.value)}}
                    placeholder="Enter a name for the Term"
                    inputProps={{
                      'data-test-id':'inline-popup-add-term',
                      'className':'add-term-input'
                    }}
                  />
                </div>
              </div>
              <div className={classes.inputContainer} style={{alignItems:'flex-start'}}>
                <Typography className={classes.inputTitle} style={{marginTop:6}}>
                  Description
                </Typography>
                <div style={{flexGrow:1}}>
                  <InputBase
                    className={classes.termInputField }
                    value={termDescription}
                    multiline
                    rows={3}
                    onChange={event=>{setTermDescription(event.target.value)}}
                    placeholder="Enter a description for the Term"
                    inputProps={{
                      'data-test-id':'inline-popup-add-description',
                      'className':'add-term-input'
                    }}
                  />
                </div>
              </div>
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                <Button style={{marginRight:16}} disabled={selectedGlossary==='none' || termName.trim()===''} onClick={()=>onAddGlossary()} color='primary'>ADD</Button>
                <Button color='secondary' onClick={()=>setCreateTermModalOpen(false)}>CANCEL</Button>
              </div>
            </div>
          }
        </Paper>
        <ModalAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          type='error'
        />
      </div>
    </ClickAwayListener>
  )

}

TextMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  anchor: PropTypes.object,
  onButtonClicked: PropTypes.func,
  onPaste: PropTypes.func,
}

export default withTheme()(withStyles(styles)(withRouter(TextMenu)));
