import React  from 'react';
import PropTypes from 'prop-types';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import Teams from './Teams';
import JobType from './JobType';

const Details = props => {

  const {
    history,
    state,
    fetchList,
  } = props;

  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/users/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        label="user"
        initialValue={state.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        data={state.basicData}
        fetchList={fetchList}
        collection="data"
        enableMarkDown={true}
        enableWidget={true}
        state={state}
      />
      <Teams
        state={state}
        history={history}
      />
      <JobType
        state={state}
        history={history}
      />
    </div>
  )
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  userName: PropTypes.string,
}

export default Details;
