import React, { useContext } from 'react';
import LandingBody from '../Landing/LandingBody/LandingBody'
import MainSearchBody from '../MainSearch/MainSearchBody/MainSearchBody'
import { BasicSearchContext } from '../../../containers/BasicSearch/BasicSearch';

const Body = props => {

  const {
    state
  } = useContext(BasicSearchContext);

  let body
  switch(state.view){
    case 'landing':
      body = <LandingBody />
      break;
    case 'main_search':
      body = (
        <MainSearchBody />
      )
      break
    default:
  }

  return (
    <div>
      {body}
    </div>
  )
}

export default Body;
