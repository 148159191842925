import React, { useEffect, useRef } from 'react';
import { withTheme, CircularProgress, withStyles, Typography, Select, MenuItem, IconButton, InputBase } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr'
import { isInViewport, getDispFields, onClickResultItem, getIconComponent } from '../../../utilities'
import ResultItem2 from '../../UI/SearchResults/ResultItem2'

const styles = theme => ({
  root: {

  },
  title:{
    fontSize:20,
    flexGrow:1,
    color:theme.palette.primary.main
  },
  errorText:{
    color:theme.palette.primaryText.main,
    marginTop:24,
  },
  timelineText:{
    color:theme.palette.primary.main,
    letterSpacing:2,
    marginBottom:8,
    fontSize:12
  },
  checkbox:{
    paddingLeft:0
  },
  selector:{
    ...theme.components.selector
  },
  searchFilter:{
    ...theme.components.inputBase,
    marginRight:24,
    width:160,
		height:40,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
})

const AccessRoles = props => {

  const {
    classes,
    theme,
    history,
    state,
    dispatch,
  } = props;

  const scrollRef = useRef()
  const searchTimeoutRef = useRef()

  const loadData = ({sort=state.accessRolesSort,search=state.accessRolesSearch,page=1}) => {
    dispatch({type:'set_access_roles',accessRolesData:page===1?undefined:state.accessRolesData,accessRolesLoading:true})
    axiosCerebrum
      .get(
        `/api/users/${state.basicData.id}/related/collections`,
        {params:{
          relationship:'MEMBER_OF_AUTO',
          parent_name:"ACCESS ROLE",
          'search.name':search,
          per_page:10,
          page:page,
          sort:sort
        }}
      )
      .then(response=>{
        if(response.data.total===0){
          dispatch({
            type:'set_access_roles',
            accessRolesData:response.data
          })
          return;
        }
        axiosSolr.get(
          `/solr/search/select`,{
            params:{
              q:'*',
              fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
              fl:'*',
              start:0,
              rows:response.data.items.length,
            }
          }
        )
        .then(solrRes=>{
          dispatch({
            type:'set_access_roles',
            accessRolesData:{
              ...response.data,
              items:[
                ...(page===1?[]:state.accessRolesData.items),
                ...response.data.items.map(el=>({
                  ...el,
                  ...(solrRes.data.response.docs.find(s=>s.id===el.id)||{})
                }))
              ]
            }
          })
        })
        .catch(error=>{
          console.log(error)
          dispatch({type:'set_access_roles',accessRolesError:true})
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_access_roles',accessRolesError:true})
      })
  }

  useEffect(()=>{
    if(!state.accessRolesData && !state.accessRolesLoading){
      loadData({})
    }
    // eslint-disable-next-line
  },[])


  const onSearchChange = value => {
    dispatch({type:'set_access_roles_search',accessRolesSearch:value})
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(()=>{
      loadData({page:1,search:value})
    },250)
  }

  const onChangeSort = (event) => {
    loadData({sort:event.target.value})
    dispatch({type:'set_access_roles_sort',accessRolesSort:event.target.value})
  }

  const shouldPaginate = () => {
    return state.accessRolesData && state.accessRolesData.page<state.accessRolesData.pages && !state.accessRolesLoading && isInViewport(scrollRef)
  }

  window.onscroll = () => {
    if(shouldPaginate()){
      loadData({page:state.accessRolesData.page+1})
    }
  }

  useEffect(()=>{
    if(shouldPaginate()){
      loadData({page:state.accessRolesData.page+1})
    }
  // eslint-disable-next-line
  },[state.accessRolesData])



  return (
    <div className={classes.root}>
      <div style={{display:'flex',alignItems:'flex-start', flexWrap:'wrap'}}>
        <Typography className={classes.title} style={{marginBottom:20}}>{state.accessRolesData?state.accessRolesData.total+' ':""}ROLE(S)</Typography>
        <div style={{display:'flex',marginBottom:20}}>
          <InputBase
            value={state.accessRolesSearch}
            onChange={event => onSearchChange(event.target.value)}
            variant={'filled'}
            placeholder={'Search'}
            className={classes.searchFilter}
            endAdornment={
              <IconButton
                disabled={state.accessRolesSearch===''}
                onClick={()=>onSearchChange('')}
                style={{width:32,height:32,marginRight:6}}
              >
                {getIconComponent({label:state.accessRolesSearch===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
              </IconButton>
            }
          />
          <Select
            className={classes.selector}
            style={{marginLeft:16,width:180}}
            value={state.accessRolesSort}
            onChange={event=>onChangeSort(event)}
            MenuProps={{
              getContentAnchorEl:null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                horizontal: "left"
              },
            }}
          >
            {
              [
                {dispName:'Recently created',value:'LINKAGE_END_DESC'},
                {dispName:'A-Z',value:'ALPHABETICAL'},
                {dispName:'Z-A',value:'REVERSE_ALPHABETICAL'}
              ].map(el=>(
                <MenuItem value={el.value}>
                  {el.dispName}
                </MenuItem>
              ))
            }

          </Select>
        </div>
      </div>
      {
         state.accessRolesData && state.accessRolesData.items.map(item=>(
          <ResultItem2
            key={item.id}
            item={item}
            dispBody={getDispFields(item,'dispBody')}
            dispSubtitle={getDispFields(item,'dispSubtitle')}
            dispTitle={getDispFields(item,'dispTitle')}
            showIcon
            label={item.object_type_txt}
            showUnderline
            tagsWidthOffset={state.editing?48:0}
            onClick={() => onClickResultItem({id:item.id, label:item.object_type_txt,history:history,item:item})}
          />
        ))
      }
      {
        state.accessRolesData && state.accessRolesData.total===0 &&
        <Typography className={classes.errorText}>No items found</Typography>
      }
      <div ref={scrollRef} style={{textAlign:'center',marginBottom:24}}>
        {
          state.accessRolesLoading &&
          <CircularProgress style={{margin:'24px auto'}} color='secondary'/>
        }
      </div>

      {
        state.accessRolesError &&
        <Typography className={classes.errorText}>Error occurred loading roles</Typography>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(AccessRoles));
