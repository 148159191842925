import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { getLabelPlural, getIconComponent, openKadaModal, closeKadaModal} from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import 'url-search-params-polyfill';
import { KadaBtn, KadaInput, KadaLoadingSpinner, KadaModal, KadaTabs, kButtonVariants, kLoadingSpinnerVariants } from 'kada-component-library';

function EmbedUrlModal(props) {

  const {
    state,
    modalOpen,
    setModalOpen
  } = props;

  const [data, setData] = useState({})
  const [isUrlSingleQuotes, setIsUrlSingleQuotes] = useState(localStorage.getItem('isWidgetUrlSingleQuotes') )
  const [isIframeSingleQuotes, setIsIframeSingleQuotes] = useState(localStorage.getItem('isWidgetIframeSingleQuotes') )

  const [tabState, setTabState] = useState(0)

  const generateIframe = link => {
    return `<iframe src="${link}" style="position: fixed; width: 100%; height: 100%; border:none;" title="${state.basicData.name}"></iframe>`
  }

  const getUrl = (tab) => {
    setData({loading:true})
    let urlEndpoint = tab === 0 ? 'embeddedfullprofile' : 'embeddedprofile'
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(state.basicData.object.name)}/${state.basicData.id}/${urlEndpoint}/link`
      )
      .then(response=>{
        let links = {
          link:response.data.embedded_profile_link,
          iframe:generateIframe(response.data.embedded_profile_link)
        }

        if(isUrlSingleQuotes){
          links.link = links.link.replace(/"/g,`'`)
        }
        if(isIframeSingleQuotes){
          links.iframe = links.iframe.replace(/"/g,`'`)
        }

        setData(links)
      })
      .catch(error=>{
        console.log(error)
        setData({error:true})
      })
  }

  useEffect(()=>{
    if(!data.link && !data.loading && modalOpen){
      getUrl(tabState)
    }
    if(modalOpen){
      openKadaModal('embed-url-modal')
    }else{
      closeKadaModal('embed-url-modal')
    }
  // eslint-disable-next-line
  },[modalOpen])

  const onChangeSingleQuotes = field => {
    if(field==='link'){
      if(isUrlSingleQuotes){
        setData({...data,[field]:data[field].replace(/'/g,`"`)})
        localStorage.removeItem('isWidgetUrlSingleQuotes')
        setIsUrlSingleQuotes(false)
      }else{
        setData({...data,[field]:data[field].replace(/"/g,`'`)})
        localStorage.setItem('isWidgetUrlSingleQuotes',true)
        setIsUrlSingleQuotes(true)
      }
    }
    if(field==='iframe'){
      if(isIframeSingleQuotes){
        setData({...data,[field]:data[field].replace(/'/g,`"`)})
        localStorage.removeItem('isWidgetIframeSingleQuotes')
        setIsIframeSingleQuotes(false)
      }else{
        setData({...data,[field]:data[field].replace(/"/g,`'`)})
        localStorage.setItem('isWidgetIframeSingleQuotes',true)
        setIsIframeSingleQuotes(true)
      }
    }
  }

  let codeTooltip = 'Use this code where the profile is embedded using a URL only.\nApplicable to Tableau etc.'
  let iframeTooltip = 'Use this link where the profile is embedded using an iframe.\nApplicable to Power BI etc.'

  return (
    <KadaModal id="embed-url-modal" width="640px" maxWidth="90vw">
      <div slot="header">
        <div style={{display:'flex',alignItems:'flex-start',background:'var(--color-base-100)'}}>
          <div style={{flexGrow:1,marginRight:16}}>
            <h2 className={'text-(--color-header-text)'}>Embedded Profile code</h2>
          </div>
          <KadaBtn variant={kButtonVariants.primaryOutlined} onClick={()=>setModalOpen(false)} text="CLOSE"></KadaBtn>
        </div>
      </div>
      <div slot='content'>
        <KadaTabs
          value={tabState}
          onChange={e=>{
            setTabState(e.detail.value)
            getUrl(e.detail.value)
          }}
          options={[
            {name: 'DETAILED'},
            {name: 'COMPACT'}
          ]}
          className="mb-6 max-w-[360px]"
        />
        {
          !data.error &&
          (
            tabState === 0 ?
            <p className="text-(--color-light-text)" style={{fontSize:12,marginBottom:24,whiteSpace:'pre-wrap'}}>
              The detailed embedding provides a comprehensive view of critical information about this report.
              {'\n\n'}
              Use this option to present a detailed summary page within the report / dashboard
              {'\n\n'}
              The profile requires a min width of 800px and a min height of 800px.
            </p>
            :
            <p className="text-(--color-light-text)" style={{fontSize:12,marginBottom:24,whiteSpace:'pre-wrap'}}>
              The compact embedding provides a condensed view of critical information for quick reference.
              {'\n\n'}
              Use this option to present information such as category, owners and warnings at a glance.
              {'\n\n'}
              The profile requires a min width of 100px and a min height of 160px.
            </p>
          )
        }
        {
          data.loading &&
          <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <KadaLoadingSpinner variant={kLoadingSpinnerVariants.secondary}/>
          </div>
        }
        {
          data.error &&
          <p>Error occurred generating the link</p>
        }
        {
          data.link &&
          <>
            <p className={'!text-xs color-(--color-primary) mb-2 tracking-wider'}>
              URL ONLY <div data-tip={codeTooltip} className={'tooltip relative inline-block ml-1.5 -top-[2px]'}>{getIconComponent({label:"info",size:16,colour:'var(--color-base-content)'})}</div>
            </p>
            <KadaInput
              readOnly
              value={data.link}
            />
            <p className={'!text-xs color-(--color-primary) mb-2 tracking-wider mt-6'}>
              HTML CODE <div data-tip={iframeTooltip} className={'tooltip relative inline-block ml-1.5 -top-[2px]'}>{getIconComponent({label:"info",size:16,colour:'var(--color-base-content)'})}</div>
            </p>
            <KadaInput
              readOnly
              value={data.iframe}
            />
            <p className={'!text-xs pl-4 mt-1 text-(--color-light-text)'}>
              Using {isIframeSingleQuotes?'single':'double'} quotes. <span className={'cursor-pointer underline'} onClick={()=>onChangeSingleQuotes('iframe')}>Change to {isIframeSingleQuotes?'double':'single'} quotes</span>
            </p>
          </>
        }
      </div>
    </KadaModal>
  )
}

EmbedUrlModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default EmbedUrlModal;
