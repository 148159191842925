import React, { useEffect, useState, useRef } from 'react';
import { withStyles, Typography, CircularProgress, InputBase, IconButton , Popper, ClickAwayListener, MenuItem, MenuList, Paper, Checkbox} from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosSolr from '../../../axios-solr';
import { getDispFields, onClickResultItem, getPartialMatchSearchString, getIconComponent, getSearchMode, sendMessage, titleCaseObjectName, formatNumber } from '../../../utilities';
import ResultItem2 from '../../UI/SearchResults/ResultItem2';
import theme from '../../../theme';
import { globalListenerRef } from '../../../GlobalListenerRef';
import { useDispatch, useStore } from 'react-redux';
import * as actions from '../../../store/actions/actionTypes';
import { checkCanAddToCart, checkCanBulkUpdate } from '../../../permissionChecker';
import axiosCerebrum from '../../../axios-cerebrum';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },
	menuItem:{
		padding:'10px 10px 10px 16px',
		color:theme.palette.primaryText.main,
		'&:hover':{
      background: theme.palette.hovered.main
    }
	},
  checkbox:{
    paddingLeft:0,
  },
  searchFilter:{
    ...theme.components.inputBase,
    flexGrow:1,
		width:160,
		height:40,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
  emptyMsg:{
		fontSize:13.75,
		colour:theme.palette.primaryText.main,
		'& span':{
			textDecoration:'underline',
			cursor:'pointer',
		}
	}
})

function ChildList(props) {

  const {
    classes,
    state,
    dispatch,
    history,
    childTypeName,
    scrollYOffset
  } = props;

	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);
	const searchTimeoutRef = useRef()

  const store = useStore();
  const sessionData = store.getState().auth.session_user;
  const tagView = state.tagView;

  const reduxDispatch = useDispatch()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`mini-profile-child-list-${state.basicData.id}`,
    isCancelledRef,
  })

  useEffect(()=>{
    return () => {
      isCancelledRef.current = true
    }
  },[])

  const getObjectNameByChildTypeName = (childTypeName) => {
    if(childTypeName==='ASSET' && state.basicData.object_type_txt==='SOURCE'){
      return 'DATABASE OR TOOL'
    }
    if(childTypeName==='ASSET' && state.basicData.object_type_txt==='TOOL'){
      return '*'
    }
    return childTypeName
  }

  const loadReferenceItemCount = () => {
		const queryParams = {
			q: '*',
			fq: `object_type_srt:(${getObjectNameByChildTypeName(childTypeName)}) AND hierarchy_parent_id_srt:${state.basicData.id} AND active_srt:YES AND reference_srt:YES`,
			fl:'*',
			rows: 0,
		};
		axiosSolr.get('/solr/search/select', { params: queryParams })
			.then(response => {
				dispatch({type:'set_reference_child_count',referenceChildCount:response.data.response.numFound})
			})
			.catch(error => {
				console.log(error)
			})
	}

  const loadChildren = ({start=0,showDeleted=state.showDeletedChild,showReference=state.showReferenceChild,search=state.childSearch}) => {
    dispatch({
      type:'set_child_list_data',
      childListLoading:true,
      childListData:start===0?undefined:state.childListData
    })

    axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:!search||search.trim()===''?'*':`name_srt:(${getPartialMatchSearchString(search,true)})`,
            fq:`object_type_srt:(${getObjectNameByChildTypeName(childTypeName)}) AND hierarchy_parent_id_srt:${state.basicData.id} ${showDeleted?'':'AND -active_srt:NO'} ${showReference?'':'AND reference_srt:NO'}`,
            start,
            rows:10
          }
        }
      )
      .then(response=>{
        if(start===0){
					if(response.data.response.numFound===0 && isNaN(state.referenceChildCount) && !search){
						loadReferenceItemCount()
					}
          dispatch({
            type:'set_child_list_data',
            childListData:{
              ...response.data.response,
            }
          })
					return;
				}
        dispatch({
          type:'set_child_list_data',
          childListData:{
            ...response.data.response,
            docs:[
              ...(start===0?[]:state.childListData?.docs || []),
              ...response.data.response.docs
            ]
          }
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_child_list_data',
          childListError:true,
        })
      })
  }

  useEffect(()=>{
    if(state.tabState!==1)return;
    if(!state.childListData && !state.childListLoading){
      loadChildren({})
    }
  // eslint-disable-next-line
  },[state.tabState])

  useEffect(()=>{
    if(state.tabState!==1)return;
    if(!state.childListData)return;
    if(state.childListLoading)return;
    let container = document.getElementById(`mini-profile-${state.basicData.id}`);
    if(!container)return;
    if(container.scrollHeight!==container.clientHeight)return;
    if(state.childListData.docs.length<state.childListData.numFound){
      loadChildren({start:state.childListData.docs.length});
    }
  // eslint-disable-next-line
  },[state.childListData, state.childListLoading, state.tabState])

  useEffect(()=>{
    let container = document.getElementById(`mini-profile-${state.basicData.id}`);
    container.removeEventListener('scroll',globalListenerRef.miniProfileScrollListener);
    if(!container)return;
    if(!state.childListData)return;
    if(state.childListLoading)return;
    if(state.tabState!==1)return;
    const onScroll = (event) => {
      if(event.target.scrollTop>=event.target.scrollHeight-event.target.clientHeight-10){
        if(!state.childListLoading && state.childListData.docs.length<state.childListData.numFound){
          loadChildren({start:state.childListData.docs.length});
        }
      }
    }
    globalListenerRef.miniProfileScrollListener = onScroll;
    container.addEventListener("scroll", globalListenerRef.miniProfileScrollListener);
    return (()=>{container.removeEventListener('scroll',globalListenerRef.miniProfileScrollListener);})
  // eslint-disable-next-line
  },[state.childListData, state.childListLoading, state.tabState])


	const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

	const onSearchChange = value => {
    dispatch({type:'set_child_search',childSearch:value})
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(()=>{
      loadChildren({search:value})
    },250)
  }

  const onClickViewInSearch = () => {
		if(getSearchMode()==='basic_search'){
      let query = `*`;
      let presetFilter = `hierarchy_parent_id_srt:${state.basicData.id} ${state.showDeletedChild?'':'AND active_srt:YES'} ${state.showReferenceChild?'':"AND reference_srt:NO "}`;
			history.push(`/basic_search?query=${query}&object=${childTypeName}&presetFilter=${presetFilter}`)
    }
	}

  const onClickAddToCart = () => {
    axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:!state.childSearch||state.childSearch.trim()===''?'*':`name_srt:(${getPartialMatchSearchString(state.childSearch,true)})`,
            fq:`object_type_srt:(${getObjectNameByChildTypeName(childTypeName)}) AND hierarchy_parent_id_srt:${state.basicData.id} ${state.showDeletedChild?'':'AND -active_srt:NO'} ${state.showReferenceChild?'':'AND reference_srt:NO'}`,
            start:0,
            rows:1000,
            fl:'id'
          }
        }
      )
      .then(response=>{
        if(response.data.response.docs.length===0){
          sendAlert({type:'error',message:'No items to add to Cart'})
          return;
        }
        axiosCerebrum
          .post(
            `/api/me/cart`,{
              object_ids:response.data.response.docs.map(el=>el.id)
            }
          )
          .then(response=>{
            sendAlert({type:'info',message:'Successfully added items to Cart'})
            sendMessage({reloadCart:true})
          })
          .catch(error=>{
            console.log(error)
            sendAlert({type:'error',message:'Error occurred adding items to Cart'})
          })
      })
      .catch(error=>{
        console.log(error)
        sendAlert({type:'error',message:'Error occurred adding items to Cart'})
      })
  }

	const onClickBulkEdit = () => {

    reduxDispatch({
      type:actions.SET_BULK_EDIT_PARAM,
      data:{
        url:'/solr/search/select',
        type:'solr',
        params:{
          q: !state.childSearch||state.childSearch.trim()===''?'*':`name_srt:(${getPartialMatchSearchString(state.childSearch,true)})`,
          fl:'*',
          fq:`object_type_srt:(${getObjectNameByChildTypeName(childTypeName)}) AND hierarchy_parent_id_srt:${state.basicData.id} ${state.showDeletedChild?'':'AND -active_srt:NO'} ${state.showReferenceChild?'':'AND reference_srt:NO'}`,
          rows:1000,
        },
        redirectUrl:window.location.pathname.split('?')[0],
        fileName:`${state.childListData?.numFound? formatNumber(state.childListData?.numFound) : ''} ${titleCaseObjectName(childTypeName).toLowerCase()}s from ${state.basicData.name} ${titleCaseObjectName(state.basicData.object_type_txt).toLowerCase()}`.trim(),
      }
    })

		history.push('/bulk_update')
	}

  const onClickShowReference = value => {
		dispatch({type:'set_show_reference_child',showReferenceChild:value})
		loadChildren({showReference:value})
	}

	const onClickShowDeleted = value => {
		dispatch({type:'set_show_deleted_child',showDeletedChild:value})
		loadChildren({showDeleted:value})
	}


  return (
    <div >
      <div style={{display:'flex', alignItems:'center',paddingBottom:12,paddingTop:2,background:theme.palette.background.main,position:'relative',zIndex:10,transform:`translate(0px,${scrollYOffset}px)`}}>
        <Typography data-test-id="child-list-title" style={{fontSize:16,color:theme.palette.header.main, flexGrow:1,marginRight:16}}>{isNaN(state.childListData?.numFound)?state.basicData.child_object_count_srt:state.childListData?.numFound} {childTypeName.replace(/_/g,' ')}(S)</Typography>
        <div data-test-id="child-list-menu" style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onListActionClick}>
          {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
        </div>
        <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
          <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
            <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
              <Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
              <MenuList className={classes.listContainer}>
                <MenuItem data-test-id="child-list-view-in-search" onClick={()=>{onClickViewInSearch()}} className={classes.menuItem} >
                  <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>View in search</Typography>
                </MenuItem>
                {
                  checkCanAddToCart({sessionData, objectType:childTypeName}) &&
                  <MenuItem data-test-id="child-list-bulk-edit" onClick={()=>{onClickAddToCart()}} disabled={!state.childListData || !state.childListData.docs || state.childListData.docs.length===0} className={classes.menuItem} >
                    <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Add to Cart</Typography>
                  </MenuItem>
                }
                {
                  checkCanBulkUpdate({sessionData}) &&
                  <MenuItem data-test-id="child-list-bulk-edit" onClick={()=>{onClickBulkEdit()}} className={classes.menuItem} >
                    <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Bulk action</Typography>
                  </MenuItem>
                }
              </MenuList>
              <Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
              <MenuItem onClick={()=>{onClickShowReference(!state.showReferenceChild)}} className={classes.menuItem} >
                <Checkbox key={state.showReferenceChild} className={classes.checkbox} color='primary' checked={state.showReferenceChild}/>
                <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show reference</Typography>
              </MenuItem>
              <MenuItem  data-test-id="child-list-show-deleted" onClick={()=>{onClickShowDeleted(!state.showDeletedChild)}} className={classes.menuItem} >
                <Checkbox key={state.showDeletedChild} className={classes.checkbox} color='primary' checked={!state.showDeletedChild}/>
                <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
              </MenuItem>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
      <div style={{display:'flex',alignItems:'center',paddingBottom:8,background:theme.palette.background.main,position:'relative',zIndex:10,transform:`translate(0px,${scrollYOffset}px)`}}>
        <InputBase
          value={state.childSearch}
          onChange={event => onSearchChange(event.target.value)}
          variant={'filled'}
          placeholder={'Search'}
          inputProps={{
            'data-test-id':"child-list-search"
          }}
          className={classes.searchFilter}
          endAdornment={
            <IconButton
              disabled={state.childSearch===''}
              onClick={()=>onSearchChange('')}
              style={{width:32,height:32,marginRight:6}}
            >
              {getIconComponent({label:state.childSearch===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
            </IconButton>
          }
        />
      </div>
      {
        state.childListData?.docs?.map((item,index)=>(
          <ResultItem2
            item={item}
            key={item.id}
            dispBody={getDispFields(item,'dispBody')}
            dispSubtitle={getDispFields(item,'dispSubtitle')}
            dispTitle={getDispFields(item,'dispTitle')}
            label={item.object_type_txt}
            showIcon
            showUnderline
            showLineageStatus
            showSimplifiedTrustIcon
            hideTrust={tagView!=='trust'}
            showTestScoreIcon={tagView==='dq_score'}
            showKnowledgeIcon={tagView==='knowledge'}
            onClick={() => onClickResultItem({id:item.id,label:item.object_type_txt,history:history,item:item,newWindow:true})}
          />
        ))
      }
      {
        state.childListData && state.childListData.numFound===0 &&
        (
          !state.searchFilter && state.basicData.child_object_count_srt ?
            !state.showReferenceChild && state.referenceChildCount ?
							<Typography className={classes.emptyMsg}>No active {childTypeName.replace(/_/g,' ').toLowerCase()}(s) found. <span onClick={()=>onClickShowReference(true)}>Click to see reference {childTypeName.replace(/_/g,' ').toLowerCase()}(s)</span></Typography>:
							<Typography className={classes.emptyMsg}>No active {childTypeName.replace(/_/g,' ').toLowerCase()}(s) found. <span onClick={()=>onClickShowDeleted(true)}>Click to see inactive {childTypeName.replace(/_/g,' ').toLowerCase()}(s)</span></Typography>
            :
            <Typography className={classes.emptyMsg}>No {childTypeName.replace(/_/g,' ').toLowerCase()}(s) found</Typography>
        )
      }
      {
        state.childListLoading &&
        <div style={{display:'flex',justifyContent:'center',marginTop:6}}>
          <CircularProgress color='secondary'/>
        </div>
      }
      {
        state.childListError &&
        <Typography>Error occurred loading items</Typography>
      }
    </div>
  )
}

ChildList.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionData: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  childTypeName: PropTypes.string.isRequired,
}

export default withStyles(styles)(ChildList);
