import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, InputBase, Paper, Typography, IconButton, Popper, Checkbox, ClickAwayListener } from '@material-ui/core';
import { getDispFields, getIconComponent, sendMessage, tabGroup} from '../../../../../../utilities';
import SimpleResultItem from '../../../../SearchResults/SimpleResultItem';
import { withRouter } from 'react-router-dom';
import UserChip from '../../../../Chips/UserChip';

const styles = theme => ({
  inputBase:{
    ...theme.components.inputBase,
    height:48,
    width:'100%'
  },
  typeFilter:{
    display:"flex",
    alignItems:'center',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  listContainer:{
    maxHeight:'80vh',
    overflow:'auto',
    ...theme.components.customScroll
  },
  tailChip:{
    flexShrink:0,
    background:theme.palette.chip.main,
    color:theme.palette.primaryText.main,
    border:`1px solid ${theme.palette.chipBorder.main}`,
    padding:'0px 8px',
    borderRadius:12,
    height:24,
    fontSize:12,
    display:'flex',
    alignItems:'center'
  },
  expandableItem:{
    height:48,
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    },
    overflow:'hidden',
    padding:'0px 16px'
  },
  itemText:{
    fontSize:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    flexGrow:1,
    marginRight:16
  },
  expandIcon:{
    width:24,
    height:24,
    marginRight:8,
    transition: "transform .3s ease",
  }
})

function NodeSearch(props) {
  const {
    classes,
    theme,
    childrenMap,
    currentGroups,
  } = props;

  const [search, setSearch] = useState('')
  const [list, setList] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [expandedId, setExpandedId] = useState(null)
  const [anchor, setAnchor] = useState(null)
  const searchRef = useRef()

  const [filters, setFilters] = useState([])


  const getType = el => el.data.obj.object_type || el.data.obj.object_type_txt

  const getListBySearch = (value, types) => {
    let items = [];
    let total = 0;
    currentGroups.forEach(g=>{
      if(g.data.label.toLowerCase().includes(value.toLowerCase()) ){
        if(types.length===0 || types.includes(getType(g))){
          if(!items.find(i=>i.id===g.data.obj.id)){
            items.push({id:g.data.obj.id, list:[g]})
          }else{
            items.find(i=>i.id===g.data.obj.id).list.push(g)
          }
          total += 1;
        }
      }
      childrenMap[g.id]?.forEach(c=>{
        if(c.data.label.toLowerCase().includes(value.toLowerCase()) ){
          if(types.length===0 || types.includes(getType(c))){
            if(!items.find(i=>i.id===c.data.obj.id)){
              items.push({id:c.data.obj.id, list:[c]})
            }else{
              items.find(i=>i.id===c.data.obj.id).list.push(c)
            }
            total += 1;
          }
        }
      })
    })
    setTotalCount(total)

    // sort items by number of items found and then alphabetically
    items.sort((a,b)=>{
      if(a.list.length!==b.list.length){
        return b.list.length-a.list.length
      }else{
        return getDispFields(a.list[0].data.obj,'dispTitle').localeCompare(getDispFields(b.list[0].data.obj,'dispTitle'))
      }
    })

    setList(items)
  }


  const getSingleItem = el => {
    let tailText = 'Start'
    if(el.level>0){
      tailText = `Downstream Level ${el.level}`
    }
    if(el.level<0){
      tailText = `Upstream Level ${Math.abs(el.level)}`
    }
    return (
      <SimpleResultItem
        key={el.id}
        title={getDispFields(el.data.obj,'dispTitle')}
        label={el.data.obj.object_type || el.data.obj.object_type_txt}
        item={el.data.obj}
        subTitle={getDispFields(el.data.obj,'dispSubtitle')}
        onClick={() => {
          document.getElementById(el.id)?.click()
          sendMessage({lineage_select_node_id:el.id, parent_node_id:el.parentNode})
        }}
        hideRight
        tailObject={
          <div className={classes.tailChip}>
            {tailText}
          </div>
        }
      />
    )
  }

  const getExpandableItem = el => {
    return (
      <div className={classes.expandableContainer}>
        <div className={classes.expandableItem} onClick={()=>expandedId===el.id?setExpandedId():setExpandedId(el.id)}>
          <div className={classes.expandIcon} style={{transform:`rotate(${el.id===expandedId?0:-90}deg)`}}>
            {getIconComponent({label:'triangle_down',size:24,colour:theme.palette.primaryText.light})}
          </div>
          <Typography className={classes.itemText}>
            {el.list[0].data.label}
          </Typography>
          <div className={classes.tailChip}>
            {el.list.length} matches
          </div>
        </div>
        <div style={{paddingLeft:32}}>
          {
            el.id===expandedId &&
            el.list.map(getSingleItem)
          }
        </div>
      </div>
    )
  }

  const generateListItem = l => {
    return l.map(el=>{
      if(el.list.length>1){
        return getExpandableItem(el)
      }else{
        return getSingleItem(el.list[0])
      }
    })


  }

  const onChangeSearch = value => {
    setSearch(value.trim())
  }

  const getAvailableTypes = () => {
    let types = []
    currentGroups.forEach(g=>{
      if(!types.includes(getType(g))) types.push(getType(g))
      childrenMap[g.id]?.forEach(c=>{
        if(!types.includes(getType(c))) types.push(getType(c))
      })
    })
    let sortedTypes = [];
    tabGroup.forEach(g=>{
      g.tabs.forEach(t=>{
        if(types.includes(t)) sortedTypes.push(t)
      })
    })
    return sortedTypes
  }

  useEffect(()=>{
    clearTimeout(searchRef.current)
    if(search.trim()===''){
      setList([])
      return;
    }
    searchRef.current = setTimeout(()=>{
      getListBySearch(search, filters)
    },100)
  // eslint-disable-next-line
  },[search, filters])

  return (
    <>
      <Paper
        style={{marginLeft:16,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main,width:560}}
      >
        <InputBase
          value={search}
          onChange={e=>onChangeSearch(e.target.value)}
          className={classes.inputBase}
          placeholder='Search map'
          endAdornment={
            <>
              {
                search.trim()!=='' &&
                <IconButton
                  onClick={e=>{
                    setSearch('')
                  }}
                  style={{width:32,height:32,marginRight:6}}
                >
                  {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              }
              <IconButton
                onClick={e=>{
                  setAnchor(e.currentTarget)
                }}
                style={{width:32,height:32,marginRight:6}}
              >
                {getIconComponent({label:'filter',size:24,colour:theme.palette.primaryText.light})}
              </IconButton>
            </>
          }
        />
        {
          filters.length>0 &&
          <div style={{marginTop:16,marginBottom:16,paddingLeft:16,display:'flex',flexWrap:'wrap'}}>
            {
              filters.map(f=>(
                <UserChip
                  key={f}
                  user={{name:f}}
                  iconLabel={f}
                  onRemove={()=>setFilters(filters.filter(el=>el!==f))}
                />
              ))
            }
          </div>
        }
        <div className={classes.listContainer} style={search.trim()===''?{}:{marginTop:16}}>
          {
            search.trim()!=='' && list.length===0 &&
            <Typography style={{padding:'0px 16px 16px'}}>No results found</Typography>
          }
          {
            list.length>0 &&
            <Typography style={{fontSize:12,marginBottom:8,marginLeft:16,letterSpacing:1.5}}>{totalCount} RESULT(S)</Typography>
          }
          {
            generateListItem(list)
          }
        </div>
      </Paper>
      <Popper open={anchor} anchorEl={anchor} placement='right-start'>
        <ClickAwayListener onClickAway={()=>setAnchor()}>
          <Paper
            style={{marginLeft:16,marginTop:-8,padding:16,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main,width:300}}
          >
            <Typography style={{fontSize:12,marginBottom:8,letterSpacing:1.5}}>FILTER BY TYPE</Typography>
            {
              getAvailableTypes().map(t=>(
                <div
                  className={classes.typeFilter}
                  onClick={()=>{
                    if(filters.includes(t)){
                      setFilters(filters.filter(f=>f!==t))
                    }else{
                      setFilters([...filters,t])
                    }
                  }}
                >
                  <Checkbox
                    color='primary'
                    checked={filters.includes(t)}
                  />
                  <Typography style={{fontSize:13.75}}>{t}</Typography>
                </div>
              ))
            }
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

NodeSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  disableFocus: PropTypes.bool,
  dispatch: PropTypes.func,
  childrenMap: PropTypes.object,
}

export default withTheme()(withStyles(styles)(withRouter(NodeSearch)));
