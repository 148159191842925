import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useAlert from '../../../../hooks/useAlert';
import useGetWorkflowStatus from '../../../../hooks/useGetWorkflowStatus';
import { KadaDropdownBtn } from 'kada-component-library';
import { collectionIds } from '../../../../utilities';
import axiosCerebrum from '../../../../axios-cerebrum';
import { globalListenerRef } from '../../../../GlobalListenerRef';

const StatusSelector = props => {
  const {
    object,
    isEditable,
    validStatus,
    setValidStatus,
    status,
    setStatus
  } = props;

  const noStatusOpt = { id: 0, name: "No Status", icon: 'unknown_circle', iconColour: 'var(--color-base-content)', colour: '#fafafa' };

  const { sendAlert } = useAlert({});

  const { data: statusData, fetchWorkflowStatus } = useGetWorkflowStatus({
    objectType: object.object.name,
    preventAuto: true
  });

  // eslint-disable-next-line
  const shouldShowStatus = () => {
    if(!object?.object?.name) return false;
    if (![
      'DATABASE',
      'SCHEMA',
      'TABLE',
      'COLUMN',
      'CONTENT_APP',
      'CONTENT',
      'CONTENT_CHILD',
      'DATASET',
      'DATASET_TABLE',
      'DATASET_FIELD',
      'DATA_PIPELINE',
      'ML_MODEL',
      'FILE',
      'WORKSPACE',
      'COLLECTION_INSTANCE',
      'TOOL',
      'ISSUE'
    ].includes(object.object.name)) return false;

    if (object.object.name === 'COLLECTION_INSTANCE') {
      if(!object.parent) return false;
      if (
        !['GLOSSARY', 'DATA_GOVERNANCE', 'DATA_MANAGEMENT'].includes(object.parent.category) &&
        ![
          collectionIds.businessLogic, collectionIds.category, collectionIds.classification, collectionIds.dataProduct, collectionIds.decision,
          collectionIds.domain, collectionIds.howTo, collectionIds.link, collectionIds.list, collectionIds.note, collectionIds.question,
          collectionIds.roadmap, collectionIds.verifiedUseCase
        ].includes(object.parent.id)
      ) return false;
    }

    return true;
  }

  useEffect(() => {
    if(!shouldShowStatus()) return;
    if (!validStatus) {
      fetchWorkflowStatus();
    }
    if (!status) {
      loadStatus();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (statusData.length > 0) {
      if(object.object.name === 'ISSUE') {
        setValidStatus(statusData);
      } else {
        setValidStatus([noStatusOpt, ...statusData]);
      }
    }
  // eslint-disable-next-line
  }, [statusData, setValidStatus]);

  // eslint-disable-next-line
  const loadStatus = () => {

    if(!status)setStatus('No Status');

    axiosCerebrum
      .get(
        `/api/nodeproperties/${object.id}`
      )
      .then(response => {
        setStatus(response.data.workflow_status || 'No Status');
      })
      .catch(error => {
        setStatus('No Status');
        console.log(error);
      });
  };

  const updateStatus = newStatus => {
    let originalStatus = status;
    setStatus(newStatus);
    axiosCerebrum
      .patch(
        `/api/${object.object?.name==='ISSUE'?'issues':'nodeproperties'}/${object.id}`, {
          workflow_status: newStatus === 'No Status' ? null : newStatus
        }
      )
      .then(response => {
      })
      .catch(error => {
        console.log(error);
        setStatus(originalStatus);
        sendAlert({
          type: 'error',
          message: 'Failed to update status'
        })
      });
  };


  useEffect(() => {
    window.removeEventListener('message', globalListenerRef.issueStatusTileListener)
    globalListenerRef.issueStatusTileListener = (msg) => {
      if (msg.data.sidebar_action==='reload_sidebar' && shouldShowStatus()) {
        loadStatus()
      }
    }
    window.addEventListener('message', globalListenerRef.issueStatusTileListener)

    return () => {
      window.removeEventListener('message', globalListenerRef.issueStatusTileListener)
    }
    // eslint-disable-next-line
  }, [object, status, loadStatus, setStatus, shouldShowStatus])


  if (!validStatus || validStatus.length === 0) return <></>;
  if (!isEditable && status === 'No Status') return <></>;
  let isExternalIssue = object.object.name === 'ISSUE' && object.external_url;

  return (
    <div className="mb-4" style={{ minWidth: 100, width: 'max-content' }}>
      <KadaDropdownBtn
        value={status}
        disabled={!isEditable || isExternalIssue}
        tooltip={
          isExternalIssue ? 'Status can\'t be changed as it\'s an external ticket' : isEditable ? 'Click to change status' : 'You do not have permission to change status'
        }
        textOnly={!isExternalIssue}
        disable
        options={validStatus.map(status => ({
          value: status.name,
          text: status.name,
          color: status.colour,
          iconColor: status.iconColour,
          icon: isExternalIssue ? 'lock' : status.icon,
          onClick: () => updateStatus(status.name)
        }))}
      />
    </div>
  );
};

StatusSelector.propTypes = {
  history: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  validStatus: PropTypes.array.isRequired,
  setValidStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired
};

export default React.memo(StatusSelector);
