import React,{useState} from 'react';
import { KadaDropdownBtn, KadaPagination, kDropdownBtnVariants } from 'kada-component-library';
import CodeDisplay from '../CodeDisplay/CodeDisplay';
import { handleShareClick, mapObjectName, titleCaseObjectName } from '../../../utilities';
import sqlFormatter from 'sql-formatter';

function ProfileCodeSection(props) {

  const {
    code,
    codeArr,
    object
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  let codeList = (codeArr || [code]).filter(el=>el);

  return (
    <div>
      <div id="profile-code-section" tabIndex={0} className="collapse collapse-arrow">
        {
          expanded &&
          <div className="flex justify-end absolute right-12 top-4.5 z-10">
            <KadaDropdownBtn
              iconName='file_copy'
              size={26}
              variant={kDropdownBtnVariants.roundedIconButton}
              iconOnly
              options={[
                {
                  text: 'Copy with formatting',
                  onClick: () => {
                    handleShareClick(sqlFormatter.format(codeList[currentIndex],{language:'pl/sql'}), 'Copied code to clipboard')
                  }
                },{
                  text: 'Copy without formatting',
                  onClick: () => {
                    handleShareClick(codeList[currentIndex], 'Copied code to clipboard')
                  }
                }
              ]}
            />
          </div>
        }
        <input
          type="checkbox"
          onChange={()=>setExpanded(!expanded)}
        />
        <div className="collapse-title flex items-center">
          <div>
            <span className="text-sm text-(--color-header-text)">
              {object.object?.name==='DATASET_FIELD'?'DEFINITION':'CODE'}
            </span>
            <p className='!text-xs text-(--color-light-text)'>
              Code snippet for this {titleCaseObjectName(mapObjectName(object.object.name))}
            </p>
          </div>
        </div>
        <div className="collapse-content">
          {
            codeList[currentIndex] ?
            <CodeDisplay
              code={codeList[currentIndex]}
            /> :
            <p>
              No code available
            </p>
          }
          {
            codeList.length>1 &&
            <KadaPagination
              maxPage={codeList.length}
              currentPage={currentIndex+1}
              onChange={e=>{
                setCurrentIndex(e.detail.value-1)
              }}
            />
          }
        </div>
      </div>
    </div>
  )
}


export default ProfileCodeSection;
